import React, { FC } from 'react';
import { Button } from '@atlasgroup/react-atlantic/lib';
import { translate, translation } from '@translations/translation';
import { useModal } from '@hooks/useModal/useModal';
import { Modals } from '@components/CenteredModal/constants/Modals';

export type SummaryDetailFooterType = FC;

export const SummaryDetailFooter: SummaryDetailFooterType = () => {
    const { toggleOpen } = useModal(Modals.SummaryDetail);

    return (
        <>
            <Button onClick={toggleOpen} type={'primary'}>
                {translate(translation.common.done)}
            </Button>
        </>
    );
};
