import { useEffect } from 'react';
import { path } from '@routes/path';
import { useNavigateWithURL } from '@hooks/useNavigateWithURL';
import { API, CurrentStatus, CurrentStatusDto } from '@api';
import { useQuery } from 'react-query';
import { getAccessToken } from '@utils/token/access/getAccessToken';

export const useContinue = () => {
    const accessToken = getAccessToken();
    const navigate = useNavigateWithURL();
    const { data } = useQuery(
        'status',
        () => API.get<CurrentStatusDto>('/status'),
        { enabled: !!accessToken }
    );
    const status = data?.status;

    useEffect(() => {
        if (status && accessToken)
            switch (status) {
                case CurrentStatus.Init: {
                    return navigate({
                        navigateParams: {
                            options: { replace: true },
                            to: `/${path.init}`,
                        },
                    });
                }

                case CurrentStatus.UserList:
                case CurrentStatus.Verifying: {
                    return navigate({
                        navigateParams: {
                            options: { replace: true },
                            to: `/${path.users}`,
                        },
                    });
                }
                case CurrentStatus.Importing: {
                    return navigate({
                        navigateParams: {
                            options: { replace: true },
                            to: `/${path.progress}`,
                        },
                    });
                }

                case CurrentStatus.Summary: {
                    return navigate({
                        navigateParams: {
                            options: { replace: true },
                            to: `/${path.summary}`,
                        },
                    });
                }
            }
    }, [status, navigate, accessToken]);
};
