import {
    ErrorMessagesStoreState,
    useErrorMessagesStore,
} from '../../stores/useErrorMessagesStore';
import { useCallback } from 'react';

export type UseErrorMessageValue = {
    showErrorMessage: (message: string) => void;
    showStatusMessage: (status: string) => void;
    resetErrorMessage: () => void;
    resetStatusMessage: () => void;
    errorMessage: string | undefined;
};

export type UseErrorMessageType = (
    key: keyof ErrorMessagesStoreState
) => UseErrorMessageValue;

export const useErrorMessage: UseErrorMessageType = (key) => {
    const [errorMessage, setErrorMessage] = useErrorMessagesStore(key);

    const setStatus = useCallback<UseErrorMessageValue['showStatusMessage']>(
        (status) => {
            setErrorMessage({ ...errorMessage, status });
        },
        [setErrorMessage]
    );

    const setError = useCallback<UseErrorMessageValue['showErrorMessage']>(
        (message) =>
            setErrorMessage({ ...errorMessage, errorMessage: message }),
        [setErrorMessage]
    );

    const resetError = useCallback<UseErrorMessageValue['resetErrorMessage']>(
        () => setErrorMessage({ ...errorMessage, errorMessage: undefined }),
        [setErrorMessage]
    );

    const resetStatus = useCallback<
        UseErrorMessageValue['resetStatusMessage']
    >(() => {
        setErrorMessage({ ...errorMessage, status: undefined });
    }, [setErrorMessage]);

    const mergedErrorMessage = errorMessage.status ?? errorMessage.errorMessage;

    return {
        showErrorMessage: setError,
        showStatusMessage: setStatus,
        resetErrorMessage: resetError,
        resetStatusMessage: resetStatus,
        errorMessage: mergedErrorMessage,
    };
};
