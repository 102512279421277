import React from 'react';
import { Button, styled } from '@atlasgroup/react-atlantic/lib';
import { Link } from '@reach/router';
import { RouteLinkButtonType } from '../types';

const ButtonLink: RouteLinkButtonType = (props) => (
    <Button as={Link} {...props} />
);

export const StyledRouteLinkButton = styled(ButtonLink)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
`;
