import React, { FC } from 'react';
import { translate, translation } from '@translations/translation';
import { useUsersFooter } from '@pages/Users/components/Footer/hooks/useUsersFooter';
import { LoadingButton } from '@components/LoadingButton';

export type UsersFooterProps = {};
export type UsersFooterType = FC<UsersFooterProps>;

export const UsersFooter: UsersFooterType = () => {
    const { isLoading, onContinueClick } = useUsersFooter();

    return (
        <LoadingButton isLoading={isLoading} onClick={onContinueClick}>
            {translate(translation.common.continue)}
        </LoadingButton>
    );
};
