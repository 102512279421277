import { useMutation, useQueryClient } from 'react-query';
import { API, DEFAULT_ERROR_MESSAGE, ImportType, UserDto } from '@api';
import { useCallback } from 'react';
import { editUsersUpdater } from '@pages/Users/hooks/utils/editUserUpdater';
import { useEditedUsersStore } from '@pages/Users/store/useEditedUsersStore';
import { useErrorMessage } from '@hooks/useErrorMessage/useErrorMessage';

export type UseEditUserValue = {
    editUsers: () => Promise<any>;
    isLoading: boolean;
};
export type UseEditUserType = () => UseEditUserValue;

export const useEditUser: UseEditUserType = () => {
    const editedUsers = useEditedUsersStore('users')[0];
    const queryClient = useQueryClient();
    const { showStatusMessage } = useErrorMessage('users');

    const onlyCheckedEditedUsers = editedUsers.filter(
        (user) => user.importType === ImportType.Include
    );

    const { mutateAsync, isLoading } = useMutation<
        Array<UserDto>,
        unknown,
        Array<UserDto>
    >(() => API.post<Array<UserDto>>(`/submit`, onlyCheckedEditedUsers), {
        onMutate: editUsersUpdater(queryClient),
        onError: (error: any) => {
            showStatusMessage(error?.body?.message ?? DEFAULT_ERROR_MESSAGE);
        },
    });

    return {
        isLoading,
        editUsers: useCallback(() => mutateAsync(onlyCheckedEditedUsers), [
            onlyCheckedEditedUsers,
            mutateAsync,
        ]),
    };
};
