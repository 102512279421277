import { useNavigateWithURL } from '@hooks/useNavigateWithURL';
import { useCallback } from 'react';
import { FORWARD_TO } from '@env/config';

export type UseRecordsManagementFooterValue = {
    onContinueClick: () => void;
};

export type UseRecordsManagementFooterType = () => UseRecordsManagementFooterValue;

export const useRecordsManagementFooter: UseRecordsManagementFooterType = () => {
    const navigate = useNavigateWithURL();

    const onContinueClick = useCallback<
        UseRecordsManagementFooterValue['onContinueClick']
    >(() => window.location.assign(FORWARD_TO), [navigate]);

    return { onContinueClick };
};
