import { Router } from '@reach/router';
import { styled } from '@atlasgroup/react-atlantic';

export const ReachRouter = styled(Router)`
    height: 100%;

    & > div[tabindex='-1'] {
        height: 100%;
    }
`;
