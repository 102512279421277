import { Environment } from './Environment';

export const DEV_ENV: Environment = {
    BACKEND_URL: 'https://nmds-devel.kube.agrp.dev/',
    FORWARD_TO: 'https://nmds-devel.kube.agrp.dev/',
    SSO_URL: 'https://sso-dev.kube.agrp.dev',
    UM_URL: 'https://users-dev.eks0.agrp.dev',
    SENTRY_DSN: '',
    COLLECT_ANALYTICS: '',
    ENV_NAME: 'dev',
};
