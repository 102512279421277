import { QueryClient } from 'react-query';
import { HttpException } from '@utils/HttpException';
import { path } from '@routes/path';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            onError(error) {
                if (error instanceof HttpException) {
                    if (error.status === 401) {
                        window.location.assign(`/${path.logout}`);
                    }
                }
                console.error(error);
            },
        },
    },
});
