import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSSOLogout } from './hooks/useSSOLogout';

export interface LogoutProps {}
export type LogoutType = FC<LogoutProps & RouteComponentProps>;

//TODO: Return loader
export const Logout: LogoutType = () => {
    useSSOLogout();
    return null;
};
