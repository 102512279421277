import { reactive, state, Store } from '@utils/Store';
import { InitInput } from '@api';

export type InitializationStoreState = {
    input: InitInput;
};

const template = new Store<InitializationStoreState>({
    input: {
        key: ``,
        id: ``,
    },
});

const initializationStore = reactive()(template);

export const useInitializationStore = state(initializationStore);
