import { reactive, state, Store } from '@utils/Store';
import { UserDto } from '@api';

export type EditedUsersStoreType = {
    users: Array<UserDto>;
};

const template = new Store<EditedUsersStoreType>({
    users: [],
});

const editedUsersStore = reactive()(template);

export const useEditedUsersStore = state(editedUsersStore);
