import { styled } from '@theme/styled';
import { StyledTitle4 } from '@atlasgroup/react-atlantic';

export const StyledModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
    height: 50px;
    padding: ${(props) => props.theme.padding.lg}
        ${(props) => props.theme.padding.xl};
    background: ${(props) => props.theme.color.background.epsilon};
    color: ${(props) => props.theme.color.text.gamma};

    ${StyledTitle4} {
        align-self: center;
        margin: 0;
        margin-right: auto;
        color: ${(props) => props.theme.color.text.gamma};
    }
`;
