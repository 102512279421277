import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { Reactive, Store } from '../store';

export type StateOptions<T> = {
    reactive?: boolean;
    initialState?: T;
};

export const state = <T extends {}>(store: Store<T> & Reactive<T>) => <
    K extends keyof T
>(
    key: K,
    options?: StateOptions<T[K]>
) => {
    const [reactState, setReactState] = useState<T[K]>(
        options?.initialState ?? (store.get(key) as T[K])
    );

    //* Sync states
    const isReactive = options?.reactive ?? true;
    useEffect(() => {
        const currentStore = store;
        const currentKey = key;

        if (isReactive) {
            setReactState(store.get(currentKey));
            currentStore.registerObserver(currentKey, setReactState);
        }
        return () => {
            currentStore.unregisterObserver(currentKey, setReactState);
        };
    }, [key, isReactive]);

    const setState = useCallback<Dispatch<SetStateAction<T[K]>>>(
        (newState) => {
            if (newState instanceof Function) {
                const next = newState(store.get(key) as T[K]);
                store.set(key, next);
                setReactState(store.get(key) as T[K]);
            } else {
                store.set(key, newState);
                setReactState(store.get(key) as T[K]);
            }
        },
        //! eslint resolves a ts generic as a dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [store, key]
    );

    return [reactState, setState] as const;
};
