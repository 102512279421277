import { ThemeType, theme as atlanticTheme } from '@atlasgroup/react-atlantic';

export enum ColorSchemeType {
    DARK = 'dark',
    LIGHT = 'light',
}

export type CustomThemeType = ThemeType<{
    color: {
        border: { custom: string };
        text: {
            zeta: string;
        };
        background: { zeta: string };
        info: {
            alpha: string;
            beta: string;
            gamma: string;
            delta: string;
            epsilon: string;
        };
        highlight: string;
    };
}>;

const commonColors = {
    primary: {
        alpha: '#D20A11',
        beta: '#DB3B40',
        gamma: '#E46C70',
        delta: '#ED9D9F',
        epsilon: '#F6CECF',
    },
    success: {
        alpha: '#7CB305',
        beta: '#96C237',
        gamma: '#B0D169',
        delta: '#CAE19B',
        epsilon: '#E5F0CD',
    },
    warning: {
        alpha: '#FFA500',
        beta: '#FFB733',
        gamma: '#FFC966',
        delta: '#FFDB99',
        epsilon: '#FFEDCC',
    },
    error: {
        alpha: '#D9121A',
        beta: '#E04147',
        gamma: '#E87075',
        delta: '#F0A0A3',
        epsilon: '#F8CFD1',
    },
    info: {
        alpha: `#1990EA`,
        beta: `#47A6EE`,
        gamma: `#75BCF2`,
        delta: `#A3D2F7`,
        epsilon: `#D1E9FB`,
    },
};

export const light: CustomThemeType = {
    ...atlanticTheme,
    color: {
        ...commonColors,
        default: '#f2f2f2',
        border: '#d9d9d9',
        text: {
            alpha: '#000000',
            beta: '#777777',
            gamma: '#FFFFFF',
            delta: '#000000',
            epsilon: '#000000',
        },
        background: {
            alpha: '#FFFFFF',
            beta: '#F2F2F2',
            gamma: '#F5F5F5',
            delta: '#FFFFFF',
            epsilon: '#2A343D',
        },
    },
    custom: {
        color: {
            border: { custom: '#3A4751' },
            text: {
                zeta: '#B3C3D2',
            },
            background: { zeta: '#222A31' },
            info: {
                alpha: `#1990EA`,
                beta: `#47A6EE`,
                gamma: `#75BCF2`,
                delta: `#A3D2F7`,
                epsilon: `#D1E9FB`,
            },
            highlight: '#FFE2AF',
        },
    },
};

export const dark: CustomThemeType = {
    ...atlanticTheme,
    color: {
        ...commonColors,
        default: '#262626',
        border: '#1D1D1D',
        text: {
            alpha: `#fff`,
            beta: `#9b9b9b`,
            gamma: `#FFF`,
            delta: `#000`,
            epsilon: '#000',
        },
        background: {
            alpha: `#2F2F2F`,
            beta: `#1c1c1c`,
            gamma: `#292929`,
            delta: `#FFF`,
            epsilon: '#1C1C1C',
        },
    },
    custom: {
        color: {
            border: { custom: '#000000' },
            text: {
                zeta: '#FFFFFF',
            },
            background: { zeta: '#1C1C1C' },
            info: {
                alpha: `#1990EA`,
                beta: `#47A6EE`,
                gamma: `#75BCF2`,
                delta: `#A3D2F7`,
                epsilon: `#D1E9FB`,
            },
            highlight: '#FFE2AF',
        },
    },
};
