import React, { FC } from 'react';
import { ProgressCircle, Skeleton, Text } from '@atlasgroup/react-atlantic';
import { translate, translation } from '@translations/translation';
import { useQuery } from 'react-query';
import { API, SummaryDto } from '@api';
import { Button } from '@atlasgroup/react-atlantic/lib';
import { useModal } from '@hooks/useModal/useModal';
import { Modals } from '@components/CenteredModal/constants/Modals';
import {
    StyledSummaryContentContainer,
    StyledSummaryProgressCircleContainer,
} from './style';
import { SummaryText } from './components';

export type SummaryDefaultContentProps = {};
export type SummaryDefaultContentType = FC<SummaryDefaultContentProps>;

export const SummaryDefaultContent: SummaryDefaultContentType = (_) => {
    const { toggleOpen } = useModal(Modals.SummaryDetail);
    const { data: summaryData, isLoading } = useQuery<SummaryDto>(
        'summaryFinished',
        () => API.get<SummaryDto>('/summary')
    );

    return (
        <StyledSummaryContentContainer>
            <StyledSummaryProgressCircleContainer>
                <ProgressCircle value={100} circleSize={80} />
            </StyledSummaryProgressCircleContainer>
            <Text element={'strong'}>
                {translate(translation.summary.default.finished)}
            </Text>
            {isLoading ? (
                <Skeleton height={30} />
            ) : (
                <SummaryText summaryData={summaryData!} />
            )}
            {summaryData?.detailText && (
                <Button onClick={toggleOpen}>
                    {translate(translation.summary.default.details)}
                </Button>
            )}
        </StyledSummaryContentContainer>
    );
};
