import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { NotFound } from '../pages';

export type ProtectedProps = { isProtected?: boolean };

// TODO:
const isLoggedIn = () => true;

export const withProtection = <P extends RouteComponentProps>(
    Component: FC<P>
): FC<P & ProtectedProps> => ({ isProtected = false, ...props }) => {
    const AnyComponent = Component as any;
    if (!isProtected) return <AnyComponent {...props} />;
    return isLoggedIn() ? <AnyComponent {...props} /> : <NotFound default />;
};
