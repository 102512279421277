import { DSN, ENV_NAME } from '@env/config';
import * as SentryBrowser from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Version } from '@utils/Version';

export class Sentry {
    constructor() {
        SentryBrowser.init({
            dsn: DSN,
            attachStacktrace: true,
            environment: ENV_NAME,
            release: Version.getAppVersion,
            enabled: !!DSN,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 1.0,
        });
    }
}
