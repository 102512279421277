import {
    StyledTitle4,
    StyledTitle1,
} from '@atlasgroup/react-atlantic/lib/components/Typography/Title/styles';
import { StyledButton } from '@atlasgroup/react-atlantic/lib/components/Button/view/default/styles';
import { styled } from '@theme/styled';

export const StyledNotFound = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const StyledNotFoundContentContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.color.background.beta};
`;

export const StyledNotFoundContent = styled.div`
    height: 100%;
    width: 100%;
    max-width: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: ${({ theme }) => theme.color.background.beta}
        url('/img/page_404.svg') center no-repeat;
    background-size: contain;

    ${StyledTitle1} {
        font-size: 80px;
        margin: 0;
    }

    ${StyledTitle4} {
        margin: 30px 0;
    }

    ${StyledButton} {
        padding: 0 ${({ theme }) => theme.padding.xl};
    }
`;
