export type PipelineStep = (...args: readonly any[]) => any;
export type PipelineType<T extends PipelineStep> = T[];

export type GetPipelineType<T> = PipelineType<
    <K extends keyof T>(
        result: T[K],
        context: {
            operation: 'get';
            parameters: readonly [key: K];
            store: Store<T>;
        }
    ) => T[K]
>;

export type SetPipelineType<T> = PipelineType<
    <K extends keyof T>(
        result: T[K],
        context: {
            operation: 'set';
            parameters: readonly [key: K, value: T[K]];
            store: Store<T>;
        }
    ) => T[K]
>;

type MethodOptionsType = {
    skipPipelines: boolean;
};

type StorePipelineType<T> = {
    get: GetPipelineType<T>;
    set: SetPipelineType<T>;
} & Record<string, PipelineType<any>>;

const DEFAULT_METHOD_OPTIONS: MethodOptionsType = { skipPipelines: false };

export class Store<T extends {}> {
    public state: T;
    public pipelines: StorePipelineType<T> = {
        get: [],
        set: [],
    };

    constructor(initialState: T) {
        this.state = initialState;
    }

    public get = <K extends keyof T>(
        key: K,
        options: MethodOptionsType = DEFAULT_METHOD_OPTIONS
    ) => {
        const result = this.state[key];

        if (options.skipPipelines) return result;

        const ctx = {
            operation: 'get' as const,
            store: this,
            parameters: [key] as const,
        };

        return this.pipelines.get.reduce(
            (result, step) => step(result, ctx),
            result
        );
    };

    public set = <K extends keyof T>(
        key: K,
        value: T[K],
        options: MethodOptionsType = DEFAULT_METHOD_OPTIONS
    ) => {
        this.state[key] = value;

        if (options.skipPipelines) return this.state[key];

        const ctx = {
            operation: 'set' as const,
            store: this,
            parameters: [key, value] as const,
        };

        return this.pipelines.set.reduce(
            (result, step) => step(result, ctx),
            this.state[key]
        );
    };
}
