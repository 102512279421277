import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { StyledCard } from './style';
import { CardProps } from './types';

export const Card = <P extends {} = {}>({
    children,
    childrenProps,
    ...props
}: PropsWithChildren<CardProps<P>>): ReactElement => {
    const Component = children as FC<P>;

    return (
        <StyledCard {...props}>
            {typeof children === 'function'
                ? Component(childrenProps ?? ({} as P))
                : children}
        </StyledCard>
    );
};
