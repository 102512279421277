import { css, DeviceType } from '@atlasgroup/react-atlantic';
import { StyledDeviceProps } from '@styles/types';
import { styled } from '@theme/styled';

export const StyledModalLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const StyledModalLayoutBody = styled.div<StyledDeviceProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 0;

    ${({ currentDevice }) =>
        (['desktop', 'laptop', 'tabletHorizontal'] as DeviceType[]).includes(
            currentDevice
        ) &&
        css`
            padding: 40px;
            background: url('/img/bg_blended.png') center no-repeat;
            background-size: cover;
            background-blend-mode: overlay;
        `}
`;

export const StyledModal = styled.div<StyledDeviceProps>`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${({ theme, currentDevice }) =>
        (['desktop', 'laptop', 'tabletHorizontal'] as DeviceType[]).includes(
            currentDevice
        )
            ? css`
                  overflow: hidden;
                  max-width: 800px;
                  max-height: 600px;
                  border-radius: ${theme.radius};
                  box-shadow: ${theme.boxShadow.md};
              `
            : css`
                  overflow: auto;
                  height: 100%;
              `}

    & > div[tabindex="-1"] {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
`;
