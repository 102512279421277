import { API } from '@api';
import { useUsers } from '@pages/Users/hooks/useUsers';
import { useQuery } from 'react-query';
import { CurrentUserDto } from 'src/api/interfaces/CurrentUserDto';

export const useUsersLimit = () => {
    const { data } = useQuery<CurrentUserDto, unknown>('currentUser', () =>
        API.get<CurrentUserDto>('/currentUser')
    );
    const maxSize = data?.licenseInfo.maxUsers;
    const { users, numberOfImportedUsers, isLoading } = useUsers(maxSize);
    const importingSize = users.length;

    const overSize = maxSize ? importingSize - maxSize : 0;
    const isOverSize = overSize > 0;
    const isSwitchDisabled = maxSize ? numberOfImportedUsers >= maxSize : false;

    return {
        overSize,
        maxSize,
        importingSize,
        isOverSize,
        numberOfImportedUsers,
        isSwitchDisabled,
        users,
        isLoading,
    };
};
