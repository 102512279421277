import React, { FC } from 'react';
import { translate, translation } from '@translations/translation';
import { useInitializationFooter } from '@pages/Initialization/components/Footer/hooks/useInitializationFooter';
import { LoadingButton } from '@components/LoadingButton';

export type InitializationFooterProps = {};
export type InitializationFooterType = FC<InitializationFooterProps>;

export const InitializationFooter: InitializationFooterType = () => {
    const { isLoading, onContinueClick } = useInitializationFooter();

    return (
        <LoadingButton isLoading={isLoading} onClick={onContinueClick}>
            {translate(translation.common.continue)}
        </LoadingButton>
    );
};
