import {
    MessageLight,
    StyledIcon,
    StyledText,
    StyledTitle5,
} from '@atlasgroup/react-atlantic';
import { styled } from '@theme/styled';
import { StyledMessageLightContainer } from '@atlasgroup/react-atlantic/lib/components/Message/view/Light/styles';

export const StyledUsersContentContainer = styled.div`
    width: 100%;

    ${StyledTitle5} {
        margin: 0 0 ${({ theme }) => theme.margin.xl} 0;
    }

    ${StyledMessageLightContainer} {
        margin-bottom: ${({ theme }) => theme.margin.xl};

        ${StyledIcon} {
            flex-shrink: 0;
        }
    }
`;

export const StyledMessageLight = styled(MessageLight)`
    background-color: ${({ theme }) => theme.color.info.epsilon};
`;

export const StyledUsersContentListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledUsersContentListHeader = styled.div`
    display: flex;

    ${StyledText} {
        width: 100%;
        font-size: ${(props) => props.theme.font.size.sm};
        color: ${(props) => props.theme.color.text.beta};
        margin: 0;

        &:last-child {
            text-align: right;
            margin-right: ${({ theme }) => theme.margin.md};
        }
    }
`;
