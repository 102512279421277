import { StyledStrongText, StyledTitle5 } from '@atlasgroup/react-atlantic';
import { StyledSmallText } from '@components/SmallText/style';
import { ModalContent, ModalHeader } from '@layouts/ModalLayout/components';
import { clipText } from '@styles/Helper.style';
import { styled } from '@theme/styled';

export const StyledLicenseHeader = styled(ModalHeader)``;

export const StyledLicenseContent = styled(ModalContent)`
    ${StyledTitle5} {
        margin: 0 0 ${({ theme }) => theme.margin.xl} 0;
    }
`;

export const StyledLicenseContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    text-align: left;

    ${StyledStrongText}, ${StyledSmallText} {
        ${clipText}
        padding-right: ${({ theme }) => theme.padding.xl};
    }
`;
