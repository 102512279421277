import React, { FC, ReactNode, useEffect } from 'react';
import {
    StyledCenteredModal,
    StyledCenteredModalBody,
    StyledCenteredModalFooter,
    StyledCenteredModalHeader,
    StyledOverlay,
} from './styles';
import { useModal } from '@hooks/useModal/useModal';
import { Modals } from '@components/CenteredModal/constants/Modals';
import { PureButton, Title, useDevice } from '@atlasgroup/react-atlantic';
import { ModalCloseButton } from './components';

export type CenteredModalProps = {
    header: string;
    content: ReactNode;
    footer?: ReactNode;
    modalKey: Modals;
};

export type CenteredModalType = FC<CenteredModalProps>;

export const CenteredModal: CenteredModalType = ({ header, content, footer, modalKey }) => {
    const { toggleOpen } = useModal(modalKey);
    const { currentDevice } = useDevice();

    useEffect(() => {
        const onEscapeClose = (e: KeyboardEvent) =>
            e.key === 'Escape' && toggleOpen();

        window.addEventListener('keydown', onEscapeClose);

        return () => window.removeEventListener('keydown', onEscapeClose);
    }, [toggleOpen]);

    return (
        <StyledOverlay onClick={toggleOpen}>
            <StyledCenteredModal
                currentDevice={currentDevice}
                onClick={(e) => e.stopPropagation()}
            >
                <StyledCenteredModalHeader>
                    <Title level={5}>{header}</Title>
                    <ModalCloseButton render={PureButton} modalKey={modalKey} />
                </StyledCenteredModalHeader>
                <StyledCenteredModalBody>{content}</StyledCenteredModalBody>
                {footer && (
                    <StyledCenteredModalFooter>
                        {footer}
                    </StyledCenteredModalFooter>
                )}
            </StyledCenteredModal>
        </StyledOverlay>
    );
};
