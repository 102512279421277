import { useInitializationStore } from '@pages/Initialization/store/useInitializationStore';
import { useErrorMessage } from '@hooks/useErrorMessage/useErrorMessage';
import { useMutation } from 'react-query';
import { API, DEFAULT_ERROR_MESSAGE } from '@api';
import { useNavigateWithURL } from '@hooks/useNavigateWithURL';
import { useCallback } from 'react';
import { path } from '@routes/path';

export type UseInitializationFooterValue = {
    onContinueClick: () => void;
    isLoading: boolean;
};

export type UseInitializationFooterType = () => UseInitializationFooterValue;

export const useInitializationFooter: UseInitializationFooterType = () => {
    const input = useInitializationStore('input')[0];
    const { showStatusMessage, resetStatusMessage } = useErrorMessage('init');
    const { mutateAsync, isLoading } = useMutation(
        'submitInitInput',
        () => API.post(`/init`, input),
        {
            onError: (error: any) => {
                showStatusMessage(
                    error?.body?.message ?? DEFAULT_ERROR_MESSAGE
                );
            },
        }
    );
    const navigate = useNavigateWithURL();

    const onContinueClick = useCallback<
        UseInitializationFooterValue['onContinueClick']
    >(async () => {
        if (!isLoading) {
            try {
                await mutateAsync();
                navigate({
                    navigateParams: {
                        to: `/${path.users}`,
                    },
                });
                resetStatusMessage();
            } catch (e) {
                console.error(e);
            }
        }
    }, [navigate, isLoading, mutateAsync, resetStatusMessage]);

    return { onContinueClick, isLoading };
};
