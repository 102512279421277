import { useNavigate } from '@reach/router';
import { useCallback } from 'react';
import { SetParamsAndPathWithNavigateProps } from './types';
import { useURL } from './useURL';

export type UseNaviagateWithURLValue = (
    props?: SetParamsAndPathWithNavigateProps
) => void;

export type UseNavigateWithURLProps = URL;
export type UseNavigateWithURLType = (
    defaultURL?: UseNavigateWithURLProps
) => UseNaviagateWithURLValue;

export const useNavigateWithURL: UseNavigateWithURLType = (defaultURL) => {
    const { setPathName, setParams, url: urlHook } = useURL(defaultURL);
    const reachNavigate = useNavigate();

    return useCallback(
        (parameters = {}) => {
            const {
                navigateParams = { options: { replace: true } },
                paramEntries,
                pathName,
            } = parameters;

            if (typeof navigateParams?.to! === 'number') {
                reachNavigate(navigateParams?.to!);
            } else {
                if (pathName) setPathName(pathName);
                if (paramEntries) setParams(paramEntries);
                if (navigateParams)
                    reachNavigate(
                        navigateParams?.to! ||
                            defaultURL?.toString()! ||
                            urlHook.toString(),
                        navigateParams?.options || {
                            replace: false,
                        }
                    );
            }
        },
        [reachNavigate, setParams, setPathName, defaultURL, urlHook]
    );
};
