import React, { FC } from 'react';
import { CustomIcon } from '@components/Icon';
import { ButtonProps } from '@atlasgroup/react-atlantic/lib';
import { StyledLoadingButton } from './styles';

export type LoadingButtonProps = {
    isLoading: boolean;
} & ButtonProps;

export type LoadingButtonType = FC<LoadingButtonProps>;

export const LoadingButton: LoadingButtonType = ({ isLoading, ...props }) => (
    <StyledLoadingButton type={'primary'} isDisabled={isLoading} {...props}>
        {isLoading && <CustomIcon name={'loading'} isRotating />}
        {props.children}
    </StyledLoadingButton>
);
