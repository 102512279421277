import { Button, Text, Title } from '@atlasgroup/react-atlantic';
import { FC } from 'react';
import { Card } from '@components/Card';
import { SmallText } from '@components/SmallText';
import { translate, translation } from '../../../../translations';
import { SSOLoginResponse } from '../../types';
import {
    StyledLicenseContainer,
    StyledLicenseContent,
    StyledLicenseHeader,
} from './style';

export type LicensesProps = {
    licenses?: SSOLoginResponse['payload'];
    onSelect: (id: string) => void;
};
export type LicensesType = FC<LicensesProps>;

export const Licenses: LicensesType = ({ licenses, onSelect }) => {
    return (
        <>
            <StyledLicenseHeader />
            <StyledLicenseContent>
                <Title level={5}>{translate(translation.SSO.header)}</Title>
                {licenses
                    ? licenses.map(({ licenseKey, licenseLabel }) => (
                          <Card
                              key={licenseKey}
                              onClick={() => {
                                  onSelect(licenseKey);
                              }}
                          >
                              <StyledLicenseContainer>
                                  <Text element="strong">
                                      {licenseLabel ??
                                          translate(
                                              translation.SSO.defaultLicense
                                          )}
                                  </Text>
                                  <SmallText>{licenseKey}</SmallText>
                              </StyledLicenseContainer>
                              <Button type="primary">
                                  {translate(translation.SSO.selectLicense)}
                              </Button>
                          </Card>
                      ))
                    : null}
            </StyledLicenseContent>
        </>
    );
};
