import { InitOptions } from 'i18next';
import { FALLBACK_LNG } from './FALLBACK_LNG';
import { Languages } from './Languages';
import { cs } from './dictionaries';

export const settings: InitOptions = {
    debug: process.env.NODE_ENV === 'development' ? true : false,
    detection: {
        order: ['localStorage', 'navigator'], //! lskey i18nextLng=LANGUAGE
    },
    fallbackLng: FALLBACK_LNG,
    interpolation: {
        escapeValue: false, // not needed for react according to docs
    },

    react: {
        bindI18n: 'languageChanged',
        bindI18nStore: 'added removed',
        useSuspense: true,
    },
    contextSeparator: '.',
    defaultNS: 'translation',
    resources: {
        [Languages.Czech]: cs,
    },
};
