import { useNavigateWithURL } from '@hooks/useNavigateWithURL';
import { useQuery } from 'react-query';
import { API, ImportingProgressDto } from '@api';
import { path, summaryPath } from '@routes/path';
import { getPercentage } from '@pages/Progress/components/Content/hooks/utils/getPercentage';

export type UseProgressContentValue = ImportingProgressDto;

export type UseProgressContentType = () => UseProgressContentValue;

export const FETCH_PROGRESS_INTERVAL = 2000;

export const useProgressContent: UseProgressContentType = () => {
    const navigate = useNavigateWithURL();
    const { data: importingProgress } = useQuery<ImportingProgressDto>(
        'progress',
        () => API.get<ImportingProgressDto>(`/progress`),
        {
            refetchInterval: FETCH_PROGRESS_INTERVAL,
            onSuccess: (progress) =>
                progress?.current &&
                progress?.total &&
                getPercentage(progress) === 100 &&
                navigate({
                    navigateParams: {
                        to: `/${path.summary}`,
                    },
                }),
            onError: () =>
                navigate({
                    navigateParams: {
                        to: `/${path.summary}/${summaryPath.error}`,
                    },
                }),
        }
    );

    return importingProgress!;
};
