import { BACKEND_URL, VERSION_PATH } from '@env/config';
import fetch from 'cross-fetch';
import packageJson from '../../package.json';

interface IVersion {
    appVersion: string;
    mainVersion: string | undefined;
    injectVersion: () => void;
    getAppVersion: string;
    getMainVersion: string | undefined;
    fetchMainVersion: () => Promise<void>;
}

type VersionBodyType = { version: string };

export class Version implements IVersion {
    public static get getAppVersion() {
        return packageJson.version;
    }

    public static injectVersion() {
        Object.defineProperty(window, 'version', {
            value: packageJson.version,
            writable: false,
        });
    }

    constructor(
        public appVersion: string = packageJson.version,
        public mainVersion: string | undefined = undefined
    ) {}

    public get getAppVersion() {
        return this.appVersion;
    }
    public get getMainVersion() {
        return this.mainVersion;
    }

    public async fetchMainVersion() {
        const result = await fetch(`${BACKEND_URL}${VERSION_PATH}`);
        const json = (await result.json()) as VersionBodyType;

        this.mainVersion = json.version;
    }

    public injectVersion() {
        Object.defineProperty(window, 'version', {
            value: this.appVersion ?? packageJson.version,
            writable: false,
        });
    }
}

export const version = new Version();
version.fetchMainVersion();
