import { FC, useCallback } from 'react';
import {
    StyledHeader,
    StyledHeaderLeftContainer,
    StyledHeaderRightContainer,
} from './style';
import { Logo } from '../Logo';
import { Indicator, Text, useDevice } from '@atlasgroup/react-atlantic';
import { path } from '@routes/path';
import { useNavigateWithURL } from '@hooks/useNavigateWithURL';
import { RouteLink } from '@components/Link';
import { translate, translation } from '@translations/translation';
import { CustomIcon } from '@components/Icon';
import { useQuery } from 'react-query';
import { API } from '@api';
import { CurrentUserDto } from '../../api/interfaces/CurrentUserDto';
import { getAccessToken } from '@utils/token';

export type HeaderProps = {};
export type HeaderType = FC<HeaderProps>;

export const Header: HeaderType = () => {
    const { currentDevice } = useDevice();
    const navigate = useNavigateWithURL();
    const { data, isLoading } = useQuery<CurrentUserDto>(
        'currentUser',
        () => API.get<CurrentUserDto>('/currentUser'),
        {
            enabled: !!getAccessToken(),
        }
    );
    const userName = data?.name;

    const handleHome = useCallback(() => {
        navigate({
            navigateParams: {
                to: `${path.root}`,
            },
        });
    }, [navigate]);

    return (
        <StyledHeader currentDevice={currentDevice}>
            <StyledHeaderLeftContainer>
                <Logo
                    onClick={handleHome}
                    onlyImage={
                        currentDevice === 'mobile' ||
                        currentDevice === 'tabletVertical'
                    }
                />
                <Text> {translate(translation.common.importHeader)}</Text>
            </StyledHeaderLeftContainer>
            <StyledHeaderRightContainer>
                <CustomIcon name="user" />
                {isLoading ? (
                    <Text>{translate(translation.common.loading)}</Text>
                ) : (
                    <Text>{userName}</Text>
                )}
                <Indicator size={'small'} type={'success'} />
                <RouteLink to={`/${path.logout}`}>
                    {translate(translation.common.logout)}
                </RouteLink>
            </StyledHeaderRightContainer>
        </StyledHeader>
    );
};
