import { Button, styled } from '@atlasgroup/react-atlantic';
import { StyledCustomIcon } from '@components/Icon/Custom/style';

export const StyledLoadingButton = styled(Button)`
    ${StyledCustomIcon} {
        margin-right: ${({ theme }) => theme.margin.sm};

        circle {
            stroke: ${({ theme }) => theme.color.text.gamma};
        }
    }
`;
