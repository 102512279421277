import { DeviceType, styled } from '@atlasgroup/react-atlantic/lib';
import { css } from '@atlasgroup/react-atlantic';
import { StyledInputContainer } from '@atlasgroup/react-atlantic/lib/components/Input/view/composed/withFix/styles';
import { StyledDeviceProps } from '@styles/types';
import { StyledSwitcherFalse } from '@atlasgroup/react-atlantic/lib/components/Switch/view/styles/Switch.style';

export type StyledUserContainerProps = {
    isValid: boolean;
};

export const StyledUserContainer = styled.div<StyledUserContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.margin.sm};
    padding: ${({ theme }) => theme.padding.md}
        ${({ theme }) => theme.padding.lg};
    border-radius: ${({ theme }) => theme.radius};
    transition: background-color 300ms ease-in-out,
        border-left 300ms ease-in-out;

    ${({ theme, isValid }) => css`
        border-left: 4px solid
            ${isValid ? theme.color.border : theme.color.primary.alpha};

        background-color: ${isValid
            ? theme.color.background.gamma
            : theme.color.primary.epsilon};
    `}
`;

export const StyledUserInformationContainer = styled.div<StyledDeviceProps>`
    ${({ currentDevice }) =>
        (['desktop', 'laptop', 'tabletHorizontal'] as DeviceType[]).includes(
            currentDevice
        )
            ? css`
                  display: flex;

                  ${StyledInputContainer} +  ${StyledInputContainer} {
                      margin-left: ${({ theme }) => theme.margin.md};
                  }
              `
            : css`
                  margin-right: ${({ theme }) => theme.margin.md};

                  ${StyledInputContainer} +  ${StyledInputContainer} {
                      margin-top: ${({ theme }) => theme.margin.sm};
                  }
              `}
`;

export const StyledUserChoiceContainer = styled.span`
    ${StyledSwitcherFalse} {
        justify-content: flex-end;
        padding-right: ${({ theme }) => theme.padding.md};
    }
`;
