import React, { FC } from 'react';
import { StyledModalContent } from './style';
import { useDevice } from '@atlasgroup/react-atlantic';

export type ModalContentProps = {
    className?: string;
};

export type ModalContentType = FC<ModalContentProps>;

export const ModalContent: ModalContentType = ({ children, className }) => (
    <StyledModalContent
        className={className}
        currentDevice={useDevice().currentDevice}
        onClick={(e) => e.stopPropagation()}
    >
        {children}
    </StyledModalContent>
);
