import { ImportType, UserDto } from '@api';

export const countEmails = (users: UserDto[]) => {
    return users.reduce((counts, user) => {
        const email = user.email?.trim();
        const key = email ?? '';
        if (user.importType === ImportType.Include) {
            if (typeof counts[key] == 'undefined') {
                counts[key] = 1;
            } else {
                counts[key] += 1;
            }
        }
        return counts;
    }, {} as Record<string, number>);
};
