import { styled, StyledIcon } from '@atlasgroup/react-atlantic';
import { StyledText } from '@atlasgroup/react-atlantic';

export const StyledErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 ${({ theme }) => theme.padding.md};
    margin-bottom: ${({ theme }) => theme.margin.md};
    background: ${({ theme }) => theme.color.primary.epsilon};
    border-radius: ${({ theme }) => theme.radius};
    border: 1px solid ${({ theme }) => theme.color.primary.alpha};

    ${StyledIcon} {
        margin-right: ${({ theme }) => theme.margin.md};
    }

    ${StyledText} {
        color: ${({ theme }) => theme.color.text.epsilon};
    }
`;
