import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ModalHeader } from '@layouts/ModalLayout/components/Header';
import { ProgressContent } from '@pages/Progress/components';
import { ModalContent } from '@layouts/ModalLayout/components';

export type ProgressProps = {};
export type ProgressType = FC<ProgressProps & RouteComponentProps>;

export const Progress: ProgressType = () => (
    <>
        <ModalHeader currentStepIndex={2} />
        <ModalContent>
            <ProgressContent />
        </ModalContent>
    </>
);
