import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useModal } from '@hooks/useModal/useModal';
import { Modals } from '@components/CenteredModal/constants/Modals';
import {
    ModalHeader,
    ModalContent,
    ModalFooter,
} from '@layouts/ModalLayout/components';
import { SummaryDefaultContent, Footer } from './components';
import { SummaryDetailModal } from '@pages/Summary/components';

export type SummaryDefaultProps = {};
export type SummaryDefaultType = FC<SummaryDefaultProps & RouteComponentProps>;

export const SummaryDefault: SummaryDefaultType = () => {
    const { isOpen } = useModal(Modals.SummaryDetail);

    return (
        <>
            <ModalHeader currentStepIndex={3} />
            <ModalContent>
                <SummaryDefaultContent />
            </ModalContent>
            <ModalFooter>
                <Footer />
            </ModalFooter>
            {isOpen && <SummaryDetailModal />}
        </>
    );
};
