import React, { FC } from 'react';
import { StyledErrorMessageContainer } from './style';
import { Text } from '@atlasgroup/react-atlantic';
import { CustomIcon } from '@components/Icon';

export const ErrorMessage: FC = ({ children }) => (
    <StyledErrorMessageContainer>
        <CustomIcon name="warning" type="error" />
        <Text>{children}</Text>
    </StyledErrorMessageContainer>
);
