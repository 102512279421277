import { loadEnvUrl } from './loadEnvUrl';

export const isProduction = process.env.NODE_ENV === 'production';
export const isTest = process.env.NODE_ENV === 'test';

// export const BACKEND_URL = 'http://192.168.3.186:4000';
export const BACKEND_URL = loadEnvUrl('BACKEND_URL');
export const FORWARD_TO = loadEnvUrl('FORWARD_TO');
export const SSO_URL = loadEnvUrl('SSO_URL');
export const UM_URL = loadEnvUrl('UM_URL');
export const DSN = loadEnvUrl('SENTRY_DSN');
export const ENV_NAME = loadEnvUrl('ENV_NAME');
export const COLLECT_ANALYTICS = loadEnvUrl('COLLECT_ANALYTICS');

export const BASE_API_PATH = '/rest/import';
export const VERSION_PATH = '/rest/status/version';
