import { createPathProxy } from './typed/createPathProxy';
import { TranslationType } from './TranslationType';
import { t } from './t';
import { pathFromProxy } from './typed/pathFromProxy';
import { pathSymbol } from './typed/pathSymbol';

export const translation = createPathProxy<TranslationType['translation']>();

export const translate = <T extends { [pathSymbol]: any }>(
    proxy: T,
    interpolations?: Record<string, string | number>
) => t(pathFromProxy(proxy), interpolations);
