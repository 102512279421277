import React, { FC } from 'react';
import { styled } from '@theme/styled';
import { Link as ReachLink } from '@reach/router';
import { Typography } from '@atlasgroup/react-atlantic';
import { RouteLinkType } from '../types';

const Link = Typography.Link as FC<any>; // Patch in atlantic

const StyledLink: RouteLinkType = (props) => <Link as={ReachLink} {...props} />;

export const StyledRouteLink = styled(StyledLink)`
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    outline: none;
`;
