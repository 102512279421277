import { FC } from 'react';
import { ReachRouter } from './Router.style';
import {
    SSORoute,
    RootRoute,
    LogoutRoute,
    LoginRoute,
    ModalLayoutRoute,
} from './Routes';
import {
    NotFound,
    Root,
    SSO,
    Logout,
    Users,
    Initialization,
    Progress,
    Summary,
    RecordsManagement,
} from '@pages/index';
import {
    progressPath,
    initPath,
    usersPath,
    path,
    ssoPath,
    logoutPath,
    summaryPath,
    recordsManagementPath,
} from '@routes/path';

export type RouterType = FC;

export const Router: RouterType = () => (
    <ReachRouter>
        <RootRoute path={path.root}>
            <NotFound default />
            <Root path={path.root} />

            <ModalLayoutRoute isProtected path={path.init}>
                <NotFound default />
                <Initialization path={initPath.root} />
            </ModalLayoutRoute>

            {/*<ModalLayoutRoute isProtected path={path.verify}>*/}
            {/*    <NotFound default />*/}
            {/*    <Verification path={verifyPath.root} />*/}
            {/*</ModalLayoutRoute>*/}

            <ModalLayoutRoute isProtected path={path.users}>
                <NotFound default />
                <Users path={usersPath.root} />
            </ModalLayoutRoute>

            <ModalLayoutRoute isProtected path={path.progress}>
                <NotFound default />
                <Progress path={progressPath.root} />
            </ModalLayoutRoute>

            <ModalLayoutRoute isProtected path={path.summary}>
                <NotFound default />
                <Summary.Default path={summaryPath.root} />
                <Summary.Error path={summaryPath.error} />
            </ModalLayoutRoute>

            <ModalLayoutRoute isProtected path={path.recordsManagement}>
                <NotFound default />
                <RecordsManagement path={recordsManagementPath.root} />
            </ModalLayoutRoute>

            <SSORoute path={path.sso}>
                <NotFound default />
                <SSO path={ssoPath.ssoToken} />
            </SSORoute>

            <LoginRoute isProtected path={path.login}>
                <NotFound default />
            </LoginRoute>

            <LogoutRoute path={path.logout}>
                <NotFound default />
                <Logout path={logoutPath.root} />
            </LogoutRoute>
        </RootRoute>
    </ReachRouter>
);
