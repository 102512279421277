import { css, Icon as IconFromAtlantic } from '@atlasgroup/react-atlantic';
import { styled } from '@theme/styled';
import { CustomIconProps } from '../types';

export const StyledCustomIcon = styled(IconFromAtlantic).withConfig({
    shouldForwardProp: (prop) => !['type', 'color'].includes(prop),
})<CustomIconProps>`
    && {
        color: ${(props) => props.theme.color.text.beta};

        ${({ type, ...props }) => {
            if (type) {
                switch (type) {
                    case 'primary': {
                        return css`
                            color: ${props.theme.color.primary.alpha};
                        `;
                    }
                    case 'success': {
                        return css`
                            color: ${props.theme.color.success.alpha};
                        `;
                    }
                    case 'warning': {
                        return css`
                            color: ${props.theme.color.warning.alpha};
                        `;
                    }
                    case 'error': {
                        return css`
                            color: ${props.theme.color.error.alpha};
                        `;
                    }
                }
            }
        }} 
        
        ${({ color }) =>
            color &&
            css`
                color: ${color};
            `}}
    }
`;
