import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useFetchSSO } from './hooks/useFetchSSO';
import { SSOPathTokenArg } from '@routes/path';
import { Licenses } from './components';
import { LoginSsoStatus } from '@pages/SSO/types';

export interface SSOProps extends SSOPathTokenArg {}
export type SSOType = FC<SSOProps & RouteComponentProps>;

export const SSO: SSOType = ({ ssoToken }) => {
    const { response, selectLicense } = useFetchSSO(ssoToken);

    return response &&
        response.loginStatus === LoginSsoStatus.MultipleLicenses ? (
        <Licenses licenses={response?.payload} onSelect={selectLicense} />
    ) : null;
};
