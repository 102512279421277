import { useEditedUsersStore } from '@pages/Users/store/useEditedUsersStore';
import { useCallback, useState } from 'react';
import { API, ImportType, UserDto } from '@api';
import { CheckboxProps } from '@atlasgroup/react-atlantic';
import { InputFixProps } from '@atlasgroup/react-atlantic/lib/components/Input/view/composed/withFix/types';
import { useQuery } from 'react-query';
import { CurrentUserDto } from '../../../../../../../api/interfaces/CurrentUserDto';
import { runUserValidation } from '../../../hooks/utils/runUserValidation';
import { ValidationErrorMessage } from '@pages/Users/types/ValidationErrorMessage';
import { translate, translation } from '@translations/translation';

export type UseUserValue = {
    onIncludedChange: NonNullable<CheckboxProps['onClick']>;
    onUserInformationChange: (
        field: EditUserField
    ) => NonNullable<InputFixProps['onChange']>;
    isIncluded: boolean;
    isValid: boolean;
    userValidityMessage: string;
};

export type UseUserType = (
    user: UserDto,
    index: number,
    isDuplicated?: boolean,
    isSwitchDisabled?: boolean
) => UseUserValue;

export const useUser: UseUserType = (
    user,
    index,
    isDuplicated,
    isSwitchDisabled
) => {
    const { data, isLoading } = useQuery<CurrentUserDto, unknown>(
        'currentUser',
        () => API.get<CurrentUserDto>('/currentUser')
    );
    const domains = data?.domainList;

    const setEditedUsers = useEditedUsersStore('users')[1];

    let userValidityMessage = '';

    userValidityMessage = runUserValidation(user, domains);

    if (userValidityMessage === ValidationErrorMessage.Valid && isDuplicated) {
        userValidityMessage = ValidationErrorMessage.DuplicatedEmail;
    }
    if (isSwitchDisabled) {
        userValidityMessage = translate(translation.users.maxImportLimit);
    }

    const isValid =
        userValidityMessage === ValidationErrorMessage.Valid && !isLoading;

    const [isIncluded, setIncluded] = useState<boolean>(
        user.importType === ImportType.Include
    );

    const onIncludedChange = useCallback<NonNullable<CheckboxProps['onClick']>>(
        (event) => {
            setIncluded(event.currentTarget.checked);

            const importType = event.currentTarget.checked
                ? ImportType.Include
                : ImportType.Exclude;

            setEditedUsers((prev) => {
                const newUsers = [...prev];
                newUsers[index].importType = importType;
                return newUsers;
            });
        },
        [index, setEditedUsers]
    );

    const onUserInformationChange = useCallback<
        UseUserValue['onUserInformationChange']
    >(
        (field) => (event) =>
            setEditedUsers((prev) => {
                const newUsers = [...prev];
                newUsers[index][field] = event.currentTarget.value.trim();
                return newUsers;
            }),
        [index, setEditedUsers]
    );

    return {
        onIncludedChange,
        isIncluded,
        onUserInformationChange,
        isValid,
        userValidityMessage,
    };
};

export type EditUserField = 'email' | 'name';
