import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useModal } from '@hooks/useModal/useModal';
import { Modals } from '@components/CenteredModal/constants/Modals';
import { SummaryDetailModal } from '@pages/Summary/components';
import {
    ModalHeader,
    ModalContent,
    ModalFooter,
} from '@layouts/ModalLayout/components';
import { SummaryErrorContent, SummaryErrorFooter } from './components';

export type SummaryErrorProps = {};
export type SummaryErrorType = FC<SummaryErrorProps & RouteComponentProps>;

export const SummaryError: SummaryErrorType = () => {
    const { isOpen } = useModal(Modals.SummaryDetail);

    return (
        <>
            <ModalHeader currentStepIndex={3} />
            <ModalContent>
                <SummaryErrorContent />
            </ModalContent>
            <ModalFooter>
                <SummaryErrorFooter />
            </ModalFooter>
            {isOpen && <SummaryDetailModal />}
        </>
    );
};
