import { FC, useMemo } from 'react';
import { Device, Skeleton, Text, Title } from '@atlasgroup/react-atlantic';
import { translate, translation } from '@translations/translation';
import { User, Users } from '@pages/Users/components/Content/components';
import { ImportType } from '@api';
import { ErrorMessage } from '@components/ErrorMessage';
import { useErrorMessage } from '@hooks/useErrorMessage/useErrorMessage';
import {
    StyledMessageLight,
    StyledUsersContentContainer,
    StyledUsersContentListContainer,
    StyledUsersContentListHeader,
} from './style';
import { useCheckAllUsersValidity } from '@pages/Users/components/Content/hooks/useCheckAllUsersValidity';
import { MessageLight } from '@atlasgroup/react-atlantic/lib/components/Message/view/Light';
import { countEmails } from '@pages/Users/utils/countEmails';
import { useUsersLimit } from './hooks/useUsersLimit';

export type UsersContentProps = {};
export type UsersContentType = FC<UsersContentProps>;

export const UsersContent: UsersContentType = () => {
    const { errorMessage } = useErrorMessage('users');
    const {
        users,
        isLoading,
        isOverSize,
        importingSize,
        maxSize,
        overSize,
        isSwitchDisabled,
        numberOfImportedUsers,
    } = useUsersLimit();
    useCheckAllUsersValidity({ users });
    const emailCounts = useMemo(() => countEmails(users), [users]);

    const importText = maxSize ? `(${numberOfImportedUsers}/${maxSize})` : '';

    return (
        <StyledUsersContentContainer>
            <Title level={5}>
                {translate(translation.users.fillInInformation)}
            </Title>

            <StyledMessageLight type="info">
                <Text>{translate(translation.users.info)}</Text>
            </StyledMessageLight>

            {isOverSize && (
                <MessageLight type="warning">
                    <Text>
                        {translate(translation.users.infoLimit, {
                            importingSize,
                            maxSize: maxSize ?? 0,
                            overSize,
                        })}
                    </Text>
                </MessageLight>
            )}

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <StyledUsersContentListContainer>
                <Device devices={['desktop', 'laptop', 'tabletHorizontal']}>
                    <StyledUsersContentListHeader>
                        <Text>{translate(translation.users.userID)}</Text>
                        <Text>{translate(translation.users.name)}</Text>
                        <Text>{translate(translation.users.email)}</Text>
                        <Text>{`${translate(
                            translation.users.import
                        )} ${importText}`}</Text>
                    </StyledUsersContentListHeader>
                </Device>
                {isLoading ? (
                    <>
                        {[...new Array(6)].map((i) => (
                            <Skeleton key={i} width={`100%`} height={50} />
                        ))}
                    </>
                ) : (
                    <Users
                        users={users}
                        emailCounts={emailCounts}
                        isSwitchDisabled={isSwitchDisabled}
                    />
                )}
            </StyledUsersContentListContainer>
        </StyledUsersContentContainer>
    );
};
