import { captureException, captureEvent, Event } from '@sentry/react';

export interface ILogger {
    log(...data: any[]): void;
    warn(...data: any[]): void;
    error(...error: any[]): void;
    event(event: Event): void;
}
type AtLeastILogger = ILogger & { [x: string]: any } & { [x: number]: any };

const defaultLoggerObj: ILogger = {
    log: console.log,
    event: console.info,
    warn: console.warn,
    error: console.error,
};

export class Logger implements ILogger {
    private logObj: AtLeastILogger;

    constructor(logObj: AtLeastILogger = defaultLoggerObj) {
        this.logObj = logObj;
    }

    public log(...data: any[]): void {
        this.logObj.log(...data);
    }
    public warn(...data: any[]): void {
        this.logObj.warn(...data);
    }
    public error(...errors: any[]): void {
        this.logObj.error(...errors);
        captureException(errors);
    }
    public event(event: Event): void {
        this.logObj.event(event);
        captureEvent(event);
    }
}

export const logger = new Logger(defaultLoggerObj);
