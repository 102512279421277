import { ImportType, UserDto } from '@api';
import { SYSDBA_ID } from '@pages/Users/constants/SYSDBA';
import { FC } from 'react';
import { User } from '../User/User';

export type UsersProps = {
    users: UserDto[];
    isSwitchDisabled: boolean;
    emailCounts: Record<string, number>;
};

export type UsersType = FC<UsersProps>;

export const Users: UsersType = ({ emailCounts, users, isSwitchDisabled }) => {
    const sysdbaUserIndex = users.findIndex((u) => u.id === SYSDBA_ID);
    const sysdbaUser = users[sysdbaUserIndex];

    return (
        <>
            {sysdbaUser && (
                <User
                    isSwitchDisabled={
                        sysdbaUser.importType === ImportType.Exclude &&
                        isSwitchDisabled
                    }
                    isDuplicated={emailCounts?.[sysdbaUser?.email!] > 1}
                    user={sysdbaUser}
                    key={sysdbaUser.id}
                    index={sysdbaUserIndex}
                />
            )}
            {users?.map((user, index) => {
                if (index === sysdbaUserIndex) return null;
                return (
                    <User
                        isSwitchDisabled={
                            user.importType === ImportType.Exclude &&
                            isSwitchDisabled
                        }
                        isDuplicated={emailCounts?.[user?.email!] > 1}
                        user={user}
                        key={user.id}
                        index={index}
                    />
                );
            })}
        </>
    );
};
