import React, { FC } from 'react';
import { translate, translation } from '@translations/translation';
import { Skeleton, Text } from '@atlasgroup/react-atlantic';
import { useQuery } from 'react-query';
import { API, SummaryDto } from '@api';

export type SummaryDetailModalContentType = FC;

export const SummaryDetailContent: SummaryDetailModalContentType = () => {
    const { data: summaryData, isLoading } = useQuery<SummaryDto>(
        'summaryFinished',
        () => API.get<SummaryDto>('/summary')
    );

    return (
        <>
            <Text element={`strong`}>
                {translate(
                    translation.summary.modal.couldNotImportTheseMessages
                )}
            </Text>
            {isLoading ? (
                <SummaryDetailLoader />
            ) : (
                <Text>{summaryData?.detailText}</Text>
            )}
        </>
    );
};

const SummaryDetailLoader = () => (
    <>
        {[...new Array(10)].map((i) => (
            <Skeleton
                key={i}
                height={30}
                width={Math.floor(Math.random() * 100) + 70}
            />
        ))}
    </>
);
