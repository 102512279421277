import { useEffect } from 'react';
import { path } from '@routes/path';
import { getAccessToken } from '@utils/token';
import { useNavigateWithURL } from '@hooks/useNavigateWithURL';
import { SSO_URL } from '@env/config';

export const useSSOLogin = () => {
    const navigate = useNavigateWithURL();

    return useEffect(() => {
        const accessToken = getAccessToken();
        if (!accessToken) {
            navigate(
                //! Must include a trailing slash
                {
                    navigateParams: {
                        to: `${SSO_URL}/?return=${window.location.origin}/${path.sso}/`,
                    },
                }
            );
        } else {
            navigate({
                navigateParams: {
                    options: { replace: true },
                    to: `/${path.init}`,
                },
            });
        }
    }, [navigate]);
};
