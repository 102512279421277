import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    StyledNotFound,
    StyledNotFoundContentContainer,
    StyledNotFoundContent,
} from './style';
import { Header } from '@components/Header';
import { Title } from '@atlasgroup/react-atlantic';
import { translate, translation } from '../../translations';
import { RouteLink } from '@components/Link/Route';

export interface NotFoundProps {}
export type NotFoundType = FC<NotFoundProps & RouteComponentProps>;

export const NotFound: NotFoundType = () => {
    return (
        <StyledNotFound>
            <Header />
            <StyledNotFoundContentContainer>
                <StyledNotFoundContent>
                    <Title level={1}>{`404`}</Title>
                    <Title level={4}>
                        {translate(translation.notFound.pageNotFound)}
                    </Title>
                    <RouteLink.Button to="/" type="primary">
                        {translate(translation.notFound.backMainPage)}
                    </RouteLink.Button>
                </StyledNotFoundContent>
            </StyledNotFoundContentContainer>
        </StyledNotFound>
    );
};
