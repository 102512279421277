import React, { FC } from 'react';
import { StyledModal, StyledModalLayout, StyledModalLayoutBody } from './style';
import { Device, useDevice } from '@atlasgroup/react-atlantic';
import { Header } from '@components/Header';

export interface ModalLayoutProps {}
export type ModalLayoutType = FC<ModalLayoutProps>;

export const ModalLayout: ModalLayoutType = ({ children }) => {
    const { currentDevice } = useDevice();

    return (
        <>
            <StyledModalLayout>
                <Device devices={['desktop', 'laptop', 'tabletHorizontal']}>
                    <Header />
                </Device>
                <StyledModalLayoutBody currentDevice={currentDevice}>
                    <StyledModal currentDevice={currentDevice}>
                        {children}
                    </StyledModal>
                </StyledModalLayoutBody>
            </StyledModalLayout>
        </>
    );
};
