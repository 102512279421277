import { useEffect, useMemo } from 'react';
import { API, UserDto } from '@api';
import { useErrorMessage } from '@hooks/useErrorMessage/useErrorMessage';
import { runUserValidation } from '@pages/Users/components/Content/hooks/utils/runUserValidation';
import { useQuery } from 'react-query';
import { CurrentUserDto } from '../../../../../api/interfaces/CurrentUserDto';
import { ValidationErrorMessage } from '@pages/Users/types/ValidationErrorMessage';
import { countEmails } from '@pages/Users/utils/countEmails';

export type UseCheckAllUsersValidityProps = {
    users: Array<UserDto>;
};

export type UseCheckAllUsersValidityType = (
    _: UseCheckAllUsersValidityProps
) => void;

export const useCheckAllUsersValidity: UseCheckAllUsersValidityType = ({
    users,
}) => {
    const { resetStatusMessage, errorMessage } = useErrorMessage('users');
    const { data } = useQuery<CurrentUserDto, unknown>('currentUser', () =>
        API.get<CurrentUserDto>('/currentUser')
    );
    const domains = data?.domainList;

    const stateAfterValidation = useMemo(() => {
        return users.map((user) => runUserValidation(user, domains));
    }, [users, domains]);

    const allUsersNamesValid = useMemo(() => {
        return !stateAfterValidation.includes(
            ValidationErrorMessage.RequiredName
        );
    }, [stateAfterValidation]);

    const allUsersEmailsValid = useMemo(() => {
        return (
            !stateAfterValidation.includes(
                ValidationErrorMessage.NotValidEmail
            ) &&
            !stateAfterValidation.includes(
                ValidationErrorMessage.RequiredEmail
            ) &&
            !stateAfterValidation.includes(
                ValidationErrorMessage.EmailDomainCollision
            )
        );
    }, [stateAfterValidation]);

    useEffect(() => {
        const isBeError = errorMessage !== 'undefined';
        //? constats with prefix all are frontend checks. this effect resets backend errors according to frontend validation check.
        if (isBeError) {
            const isErrorMessageWithName = errorMessage
                ?.toLowerCase()
                ?.includes('jméno');
            if (allUsersNamesValid && isErrorMessageWithName) {
                resetStatusMessage();
            }

            const beMissingEmailSubstring = 'nemá zadán email';
            const beNotValidEmailSubstring = 'není validní';

            if (allUsersEmailsValid && !isErrorMessageWithName) {
                const beNotValidOrMissing =
                    errorMessage
                        ?.toLowerCase()
                        ?.includes(beNotValidEmailSubstring) ||
                    errorMessage
                        ?.toLowerCase()
                        ?.includes(beMissingEmailSubstring);
                if (beNotValidOrMissing) {
                    resetStatusMessage();
                } else {
                    //collision emails?
                    const counter = countEmails(users);
                    const isCollision = Object.values(counter).some(
                        (count) => count > 1
                    );

                    if (!isCollision) {
                        resetStatusMessage();
                    }
                }
            }
        }
    }, [
        allUsersNamesValid,
        allUsersEmailsValid,
        resetStatusMessage,
        errorMessage,
        users,
    ]);
};
