import React, { FC } from 'react';
import { Text } from '@atlasgroup/react-atlantic';
import { translate, translation } from '@translations/translation';
import { SummaryDto } from '@api';

export type SummaryTextProps = {
    summaryData: SummaryDto;
};
export type SummaryTextType = FC<SummaryTextProps>;

export const SummaryText: SummaryTextType = ({ summaryData }) => (
    <>
        <Text>
            {translate(translation.summary.default.imported, {
                mailboxes: summaryData?.imported.mailboxes!,
                users: summaryData?.imported.users!,
                messages: summaryData?.imported.messages!,
            })}
        </Text>
        <Text>
            {translate(translation.summary.default.additionalFinished)}
        </Text>
        {!!summaryData?.failed.mailboxes && (
            <Text>
                {translate(translation.summary.default.failed.mailboxes, {
                    data: summaryData?.failed.mailboxes!,
                })}
            </Text>
        )}
        {!!summaryData?.failed.messages && (
            <Text>
                {translate(translation.summary.default.failed.messages, {
                    data: summaryData?.failed.messages!,
                })}
            </Text>
        )}
        {!!summaryData?.failed.users && (
            <Text>
                {translate(translation.summary.default.failed.users, {
                    data: summaryData?.failed.users!,
                })}
            </Text>
        )}
    </>
);
