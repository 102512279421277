import { TranslationType } from '../TranslationType';

export const cs: TranslationType = {
    translation: {
        users: {
            maxImportLimit:
                'Nelze importovat do licence kvůli přesahnutí maximálního počtu uživatelů',
            notValidUser: `Nelze importovat uživatele bez e-mailu`,
            fillInInformation: `Doplňte prosím údaje k účtům, které chcete importovat`,
            info:
                'Níže jsou zobrazení uživatelé vaší desktopové verze Manažera datových schránek. K jejich úspěšnému navedení prosím níže doplňte/zkontrolujte jejich JMÉNA a E-MAILOVÉ adresy.',
            userID: 'ID uživatele',
            name: 'Jméno',
            email: 'E-mail',
            import: 'Importovat',
            infoLimit:
                'Provádíte převod {{importingSize}} uživatelů do licence programu s maximálním počtem {{maxSize}} uživatelů. Prosím označte posuvníkem uživatele, které chcete do Manažera datových schránek zavést. Pro navýšení licenčního počtu uživatelů prosím kontaktujte našeho obchodního zástupce.',
        },
        initialization: {
            enterPin: `Zadejte, prosím, ID a PIN importu`,
            whereToFindPinHint: `ID importu a PIN naleznete po exportu vašich dat z desktopové aplikace Manažer datových schránek (Databáze/Export do MDS Online)`,
            errorMessage: `Zadaný PIN není správný`,
            id: `ID importu`,
            pin: `PIN`,
        },
        summary: {
            modal: {
                couldNotImportTheseMessages: `Nepodařilo se nahrát tyto zprávy, ZFO soubory jsou požkozeny:`,
                title: `Podrobnosti exportu`,
            },
            default: {
                finished: `Import dat z desktopové verze byl dokončen`,
                additionalFinished:
                    'Zároveň bylo migrováno stávající nastavení uživatelských složek, pravidel, adresářů a nastavení SMTP serveru.',
                imported: `Bylo importováno {{mailboxes}} schránek, {{users}} uživatelů a {{messages}} zpráv.`,
                failed: {
                    messages: `Nepodařilo se importovat {{data}} zpráv.`,
                    users: `Nepodařilo se importovat {{data}} uživatelů.`,
                    mailboxes: `Nepodařilo se importovat {{data}} schránek.`,
                },
                details: `Podrobnosti`,
            },
            error: {
                failure: `Import dat se nezdařil`,
                thereWasAnError: `Během importu dat došlo k chybě.`,
                importAgain: `Importovat znovu`,
                details: `Podrobnosti`,
            },
        },
        verify: {
            loading: `Načítání informací o vašem importu`,
            mayTakeAWhile: `Tato operace může trvat několik minut.`,
        },
        progress: {
            loading: `Probíhá import vašich schránek, uživatelů, zpráv a nastavení.`,
            mayTakeAWhile: `Tato operace může trvat několik minut.`,
        },
        SSO: {
            header: 'Vyberte licenci',
            selectLicense: 'Vybrat licenci',
            defaultLicense: 'Manažer datových schránek cloud',
        },
        recordsManagement: {
            continueToApplication: 'Přejít do aplikace',
            title: 'Nastavení datových schránek v aplikaci',
            heading:
                'Bezpečné připojení vašich datových schránek do systému České pošty přes tzv. Hostovanou spisovou službu',
            hssInfoPrefix:
                'Počty navedených schránek v MDS rostou obrovským tempem. Dohodli jsme se tak s Českou poštou na způsobu připojení k jejich serveru formou',
            hssInfoStrong: 'Hostované spisové služby',
            hssInfoSuffix:
                'přinášející větší kapacitu připojení a vyšší zabezpečení.',
            helpNeededPrefix:
                'Budeme potřebovat vaši pomoc. Po přechodu do aplikace budou zmigrované datové schránky v režimu archivu bez možnosti přijímat a odesílat datové zprávy. Ukážeme vám jak přes',
            helpNeededLink: 'www.mojedatovaschranka.cz',
            helpNeededSuffix: 'zajistit plnou funkčnost.',
        },
        notFound: {
            error: 'Chyba 404',
            pageNotFound: 'Omlouváme se, stránka nebyla nalezena',
            backMainPage: 'Zpět na hlavní stránku',
            noResultsTitle: 'Nebyly nalezeny žádné výsledky…',
            noResultsSubtitle:
                'Zadejte jiný výraz k vyhledání nebo změňte parametry filtrace.',
            noRecordsTitle: 'Nebyly nalezeny žádné záznamy…',
            noRecordsSubtitle:
                'Pro zobrazení záznamů změňte prosím parametry filtrace..',
        },
        editUserModal: {
            mandatoryFields: `Pole {{field}} je povinné`,
        },
        common: {
            importTitle: 'Import dat',
            importHeader: 'Import dat z desktop aplikace',
            edit: `Upravit`,
            copy: `Kopírovat`,
            delete: 'Smazat',
            no: 'Ne',
            yes: 'Ano',
            close: 'Zavřít',
            reset: 'Resetovat',
            continue: 'Pokračovat',
            back: 'Zpět',
            loading: 'Načítání',
            finish: 'Dokončit',
            setting: 'Nastavení',
            save: 'Uložit',
            login: 'Přihlašovací údaje',
            logout: 'Odhlásit se',
            license: 'Licence',
            cancel: 'Zrušit',
            confirm: 'Potvrdit',
            stop: 'Zastavit',
            done: 'Hotovo',
            manual: 'Manuál',
            manualHint: 'Průvodce jednotlivých kroků převodu',
            support:
                'V případě problému s migrací se obraťte na naše klienstké centrum',
        },
    },
};
