import { StyledRouteLink } from './style';
import { RouteLinkButton, RouteLinkButtonType } from './components';
import { RouteLinkType } from './types';
import { dotNotation } from '@utils/dotNotation';

type Type = RouteLinkType & { Button: RouteLinkButtonType };

export const RouteLink: Type = dotNotation(StyledRouteLink, {
    Button: RouteLinkButton,
});
