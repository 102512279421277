import React, { FC } from 'react';
import { Title } from '@atlasgroup/react-atlantic';
import { StyledModalHeader } from './style';
import { translate, translation } from '@translations/translation';
import { StepsText } from '@components/StepsText';

export type ModalHeaderProps = {
    currentStepIndex?: number;
    title?: string;
};
export type ModalHeaderType = FC<ModalHeaderProps>;

export const ModalHeader: ModalHeaderType = ({
    children,
    currentStepIndex,
    title = translate(translation.common.importTitle),
}) => {
    return (
        <StyledModalHeader>
            <Title level={4}>{title}</Title>
            {currentStepIndex && <StepsText currentStep={currentStepIndex} />}
            {children}
        </StyledModalHeader>
    );
};
