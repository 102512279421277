import React, { FC } from 'react';
import { StyledStepText } from './style';
import { Text } from '@atlasgroup/react-atlantic';

export type StepsTextProps = {
    currentStep: number;
};
export type StepsTextType = FC<StepsTextProps>;

export const LAST_STEP_NUMBER = 4;

export const StepsText: StepsTextType = ({ currentStep }) => (
    <StyledStepText>
        <Text>{`0${currentStep}`}</Text>
        <Text>{`/`}</Text>
        <Text>{`0${LAST_STEP_NUMBER}`}</Text>
    </StyledStepText>
);
