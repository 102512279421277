import { Store } from '../../Store';

const DEFAULT_PERSISTED_STORAGE_KEY = 'store';

type PersistOptionsType = {
    storage: Storage;
    storageKey: string;
    rehydrate: boolean;
    autopersist: boolean;
    serialize: (value: any) => string;
    parse: (value: string) => any;
};
const DEFAULT_OPTIONS: PersistOptionsType = {
    storage: sessionStorage,
    storageKey: DEFAULT_PERSISTED_STORAGE_KEY,
    rehydrate: true,
    autopersist: true,
    serialize: JSON.stringify,
    parse: JSON.parse,
};

export type Persistant = {
    storage: Storage;
    storageKey: string;
    persist: () => void;
    rehydrate: () => void;
    clear: () => void;
};

export const persistant = (
    persistOptions: Partial<PersistOptionsType> = {}
) => <T extends Store<any>>(store: T): T & Persistant => {
    const options = { ...DEFAULT_OPTIONS, ...persistOptions };

    const persistantStore = Object.assign(store, {
        storage: options.storage,
        storageKey: options.storageKey,

        persist() {
            this.storage.setItem(
                this.storageKey,
                options.serialize(this.state)
            );
        },

        clear() {
            this.storage.removeItem(this.storageKey);
        },

        rehydrate() {
            const storedValue = this.storage.getItem(this.storageKey);
            if (storedValue) this.state = options.parse(storedValue);
            else console.warn('Failed to rehydrate Store');
        },
    } as Persistant & T);

    const rehydrate = persistantStore.rehydrate.bind(persistantStore);
    const persist = persistantStore.persist.bind(persistantStore);

    if (options.rehydrate) rehydrate();
    if (options.autopersist) window.addEventListener('beforeunload', persist);

    return persistantStore;
};
