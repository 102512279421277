import React, { FC } from 'react';
import { translate, translation } from '@translations/translation';
import { Button } from '@atlasgroup/react-atlantic/lib';
import { useSummaryFooter } from '@pages/Summary/views/Default/components/Footer/hooks/useSummaryFooter';

export type FooterProps = {};
export type FooterType = FC<FooterProps>;

export const Footer: FooterType = () => {
    const onSubmit = useSummaryFooter();

    return (
        <Button type={'primary'} onClick={onSubmit}>
            {translate(translation.common.continue)}
        </Button>
    );
};
