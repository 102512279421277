import {
    Tooltips as AtlanticTooltips,
    useDevice,
} from '@atlasgroup/react-atlantic';
import { FC } from 'react';

export interface TooltipsProps {}
export type TooltipsType = FC<TooltipsProps>;

export const Tooltips: TooltipsType = ({ children }) => {
    const { isTouchable } = useDevice();
    return (
        <AtlanticTooltips hideTooltips={isTouchable}>
            {children}
        </AtlanticTooltips>
    );
};
