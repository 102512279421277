import { registerLocale } from 'react-datepicker';
import { Languages } from './Languages';
import cs from 'date-fns/locale/cs';

const datePickerLocaleMap: Record<Languages, Locale> = {
    'cs-CZ': cs,
};

export const registerLocalDatePicker = () => {
    (Object.keys(datePickerLocaleMap) as Languages[]).forEach((k) => {
        const v = datePickerLocaleMap[k];
        registerLocale(k, v);
    });
};
