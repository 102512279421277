import { styled, StyledText } from '@atlasgroup/react-atlantic/lib';
import { StyledIcon } from '@atlasgroup/react-atlantic';
import { StyledInput } from '@atlasgroup/react-atlantic/lib/components/Input/view/base/styles';
import { StyledInputContainer } from '@atlasgroup/react-atlantic/lib/components/Input/view/composed/withFix/styles';

export const InitializationContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 450px;

    ${StyledIcon} {
        width: 80px;
        height: 80px;
        margin-bottom: ${({ theme }) => theme.margin.xl};
    }

    ${StyledText} {
        margin-bottom: ${({ theme }) => theme.margin.xl};
    }
`;

export const InitializationContentFormContainer = styled.div`
    max-width: 400px;

    ${StyledInputContainer}:first-child {
        margin-bottom: ${({ theme }) => theme.margin.md};
    }

    ${StyledInput} {
        text-align: center;
    }
`;
