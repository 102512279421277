import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useContinue } from './hooks/useContinue';
import { useSSOLogin } from '@pages/Root/hooks/useSSOLogin';

export interface RootProps {}
export type RootType = FC<RootProps & RouteComponentProps>;

//TODO: Return loader
export const Root: RootType = () => {
    useSSOLogin();
    useContinue();
    return null;
};
