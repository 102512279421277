import { BACKEND_URL, BASE_API_PATH } from '@env/config';
import crossFetch from 'cross-fetch';
import { HttpException } from '@utils/HttpException';

export class Api {
    private static fetch = crossFetch;
    private static toJson<T extends {}>(response: Response): Promise<T> {
        return response.json();
    }

    constructor(private backendUrl: string, private init: RequestInit = {}) {}

    private async fetchJson<T extends any[] | {} | undefined>(
        path: string,
        body?: T,
        init: Partial<Omit<RequestInit, 'body'>> = {}
    ): Promise<T> {
        const json = body && JSON.stringify(body);

        const res = await Api.fetch(`${this.backendUrl}${path}`, {
            ...this.init,
            ...init,
            credentials: 'include',
            body: json,
        });

        if (res.ok) return (Api.toJson(res) as unknown) as T;
        const jsonError = await Api.toJson(res);

        throw new HttpException(res.status, jsonError);
    }

    public get<T>(path: string, init: Partial<Omit<RequestInit, 'body'>> = {}) {
        return this.fetchJson<T>(path, undefined, init);
    }

    public post<T>(
        path: string,
        body?: T,
        init: Partial<Omit<RequestInit, 'body' | 'method'>> = {}
    ) {
        const reqInit = { method: 'POST', ...init };
        return this.fetchJson(path, body, reqInit);
    }

    public put<T>(
        path: string,
        body?: T,
        init: Partial<Omit<RequestInit, 'body' | 'method'>> = {}
    ) {
        const reqInit = { method: 'PUT', ...init };
        return this.fetchJson(path, body, reqInit);
    }
}
export const API = new Api(`${BACKEND_URL}${BASE_API_PATH}`, {
    headers: { 'Content-Type': 'application/json' },
});
