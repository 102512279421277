import { useErrorMessage } from '@hooks/useErrorMessage/useErrorMessage';
import { useEditUser } from '@pages/Users/hooks/useEditUser';
import { useNavigateWithURL } from '@hooks/useNavigateWithURL';
import { useCallback } from 'react';
import { path } from '@routes/path';

export type UseUsersFooterValue = {
    onContinueClick: () => void;
    isLoading: boolean;
};

export type UseUsersFooterType = () => UseUsersFooterValue;

export const useUsersFooter: UseUsersFooterType = () => {
    const { resetStatusMessage } = useErrorMessage('users');
    const { editUsers, isLoading } = useEditUser();
    const navigate = useNavigateWithURL();

    const onContinueClick = useCallback(async () => {
        if (!isLoading) {
            try {
                await editUsers();
                navigate({
                    navigateParams: {
                        to: `/${path.progress}`,
                    },
                });
                resetStatusMessage();
            } catch (e) {
                console.error(e);
            }
        }
    }, [navigate, editUsers, isLoading, resetStatusMessage]);
    return { onContinueClick, isLoading };
};
