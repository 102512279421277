import { pathSymbol } from "./pathSymbol"
import { PathProxyType } from "./PathSymbolType"

export const createPathProxy = <T>(
  path: PropertyKey[] = []
): PathProxyType<T, T> =>
  (new Proxy(
    { [pathSymbol]: path },
    {
      get: (target, key) =>
        key === pathSymbol
          ? target[pathSymbol]
          : createPathProxy([...path, key]),
    }
  ) as unknown) as PathProxyType<T, T>
