import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ErrorMessage } from '@components/ErrorMessage';
import { useErrorMessage } from '@hooks/useErrorMessage/useErrorMessage';
import {
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@layouts/ModalLayout/components';
import { InitializationContent, InitializationFooter } from './components';

export type InitializationProps = {};
export type InitializationType = FC<InitializationProps & RouteComponentProps>;

export const Initialization: InitializationType = () => {
    const { errorMessage } = useErrorMessage('init');

    return (
        <>
            <ModalHeader currentStepIndex={1} />
            <ModalContent>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <InitializationContent />
            </ModalContent>
            <ModalFooter>
                <InitializationFooter />
            </ModalFooter>
        </>
    );
};
