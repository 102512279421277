import { reactive, state, Store } from '@utils/Store';

export type ErrorMessageType = {
    errorMessage?: string;
    status?: string;
};

export type ErrorMessagesStoreState = {
    users: ErrorMessageType;
    init: ErrorMessageType;
};

const template = new Store<ErrorMessagesStoreState>({
    users: {},
    init: {},
});

const errorMessagesStore = reactive()(template);

export const useErrorMessagesStore = state(errorMessagesStore);
