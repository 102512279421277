import React, { FC } from 'react';
import { translate, translation } from '@translations/translation';
import { Button } from '@atlasgroup/react-atlantic/lib';

export type SummaryErrorFooterProps = {};
export type SummaryErrorFooterType = FC<SummaryErrorFooterProps>;

export const SummaryErrorFooter: SummaryErrorFooterType = () => (
    <Button type={'primary'}>
        {translate(translation.summary.error.importAgain)}
    </Button>
);
