import { object, string } from 'yup';
import { ImportType } from '../enums';
import { validateDomains } from '@pages/Users/utils/validateDomains';
import { ValidationErrorMessage } from '@pages/Users/types/ValidationErrorMessage';

export const UserInputSchema = object()
    .shape({
        id: string().required(),
        name: string()
            .trim(ValidationErrorMessage.RequiredName)
            .required(ValidationErrorMessage.RequiredName),
        email: string()
            .required(ValidationErrorMessage.RequiredEmail)
            .email(ValidationErrorMessage.NotValidEmail)
            .test(
                'domain',
                ValidationErrorMessage.EmailDomainCollision,
                function (value) {
                    return validateDomains(
                        value!,
                        this.options.context?.domains
                    )!;
                }
            ),
        importType: string().oneOf(Object.values(ImportType)).optional(),
    })
    .noUnknown()
    .strict();
