import { UserDto, UserInputSchema } from '@api';
import { ValidationErrorMessage } from '@pages/Users/types/ValidationErrorMessage';
import { ValidationError } from 'yup';

export const runUserValidation = (
    user: UserDto,
    domains: Array<string> = []
): ValidationErrorMessage => {
    try {
        UserInputSchema.validateSync(user!, {
            context: {
                domains,
            },
        });
        return ValidationErrorMessage.Valid;
    } catch (e) {
        if (e instanceof ValidationError) {
            const messageText = e.message;
            return messageText as ValidationErrorMessage;
        }
        return ValidationErrorMessage.Unknown;
    }
};
