import fetch from 'cross-fetch';
import { isSSOLoginResponse } from '@pages/SSO/hooks/utils/isSSOLoginResponse';
import { SSOLoginResponse } from '@pages/SSO/types';

export const fetchAppTokenForLicenseId = async (
    ssoToken: string,
    selectedLicenseKey: string,
    targetUrl: string
): Promise<SSOLoginResponse | undefined> => {
    const response = await fetch(targetUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ ssoToken, selectedLicenseKey }),
    });
    if (!response.ok) {
        throw response;
    }

    const responseData = await response.json();

    return isSSOLoginResponse(responseData) ? responseData : undefined;
};
