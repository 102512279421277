import React, { FC } from 'react';
import {
    InitializationContentContainer,
    InitializationContentFormContainer,
} from './styles/Content.style';
import { Input, Text } from '@atlasgroup/react-atlantic';
import { translate, translation } from '@translations/translation';
import { CustomIcon } from '@components/Icon';
import { useInitializationContent } from '@pages/Initialization/components/Content/hooks/useInitializationContent';

export type InitializationContentProps = {};
export type InitializationContentType = FC<InitializationContentProps>;

export const InitializationContent: InitializationContentType = () => {
    const { input, onFieldChange, onEnterPress } = useInitializationContent();

    return (
        <InitializationContentContainer>
            <CustomIcon name={'fileImport'} />
            <Text element={'strong'}>
                {translate(translation.initialization.enterPin)}
            </Text>
            <Text>
                {translate(translation.initialization.whereToFindPinHint)}
            </Text>
            <InitializationContentFormContainer>
                <Input
                    isFullWidth
                    onChange={onFieldChange('id')}
                    defaultValue={input.id}
                    placeholder={translate(translation.initialization.id)}
                    onKeyDown={onEnterPress}
                />
                <Input
                    isFullWidth
                    onChange={onFieldChange('key')}
                    defaultValue={input.key}
                    placeholder={translate(translation.initialization.pin)}
                    onKeyDown={onEnterPress}
                />
            </InitializationContentFormContainer>
        </InitializationContentContainer>
    );
};
