import { BACKEND_URL } from '@env/config';
import { useState, useEffect, useCallback } from 'react';
import fetch from 'cross-fetch';
import { logger } from '@utils/Sentry';
import { path } from '@routes/path';
import { fetchAppTokenForLicenseId } from './utils/fetchAppTokenForLicenseId';
import { useNavigateWithURL } from '../../../hooks/useNavigateWithURL';
import { LoginSsoStatus, SSOLoginResponse } from '@pages/SSO/types';
import { setAccessToken } from '@utils/token';
import { isSSOLoginResponse } from '@pages/SSO/hooks/utils/isSSOLoginResponse';

export const useFetchSSO = (
    ssoToken?: string,
    targetUrl: string = `${BACKEND_URL}/rest/sso/login`
) => {
    const [response, setResponse] = useState<SSOLoginResponse>();
    const navigate = useNavigateWithURL();

    const setAppToken = useCallback((appToken?: string) => {
        if (appToken) {
            setAccessToken(appToken);
            navigate({ navigateParams: { to: `/${path.init}` } });
        }
    }, [navigate]);

    const handleResponse = useCallback(
        (res: Response['json']) => {
            if (isSSOLoginResponse(res)) {
                if (res.loginStatus === LoginSsoStatus.Ok) {
                    setAppToken(res.appToken);
                } else if (
                    res.loginStatus === LoginSsoStatus.MultipleLicenses
                ) {
                    setResponse(res);
                } else {
                    throw res;
                }
            } else {
                throw res;
            }
        },
        [setResponse, setAppToken]
    );

    useEffect(() => {
        if (ssoToken) {
            (async () => {
                try {
                    const response = await (
                        await fetch(targetUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            credentials: 'include',
                            body: JSON.stringify({ ssoToken }),
                        })
                    ).json();

                    handleResponse(response);
                } catch (err) {
                    navigate({ navigateParams: { to: `/${path.logout}` } });
                    logger.error(err);
                }
            })();
        }
    }, [navigate, ssoToken, targetUrl, handleResponse]);

    const selectLicense = useCallback(
        async (id: string) => {
            try {
                if (!ssoToken) {
                    logger.error('SSO Token is missing!', { ssoToken });
                    return;
                }

                const response = await fetchAppTokenForLicenseId(
                    ssoToken,
                    id,
                    targetUrl
                );

                setAppToken(response?.appToken);
            } catch (err) {
                navigate({ navigateParams: { to: `/${path.logout}` } });
                logger.error(err);
            }
        },
        [navigate, ssoToken, targetUrl, setAppToken]
    );

    return { response, selectLicense };
};
