import { useCallback } from 'react';
import { useModalStoreState } from '@components/CenteredModal/store/useModalStore';
import { Modals } from '@components/CenteredModal/constants/Modals';

export type UseModalValue = {
    toggleOpen: () => void;
    isOpen: boolean;
};

export type UseModalType = (key: Modals) => UseModalValue;

export const useModal: UseModalType = (key) => {
    const [visibilityMap, setVisibilityMap] = useModalStoreState(
        'visibilityMap'
    );
    const isOpen = visibilityMap.get(key) || false;

    const toggleOpen = useCallback(
        () =>
            setVisibilityMap((prev) => {
                const newMap = new Map(prev);
                const isModalOpen = prev.get(key);
                newMap.set(key, !isModalOpen);
                return newMap;
            }),
        [setVisibilityMap, key]
    );

    return {
        isOpen,
        toggleOpen,
    };
};
