import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ModalLayout } from '../layouts';
import { withProtection, ProtectedProps } from './withProtection';

type ProtectedRouteType = FC<RouteComponentProps & ProtectedProps>;

export const RootRoute: ProtectedRouteType = withProtection(({ children }) => (
    <>{children}</>
));

export const ModalLayoutRoute: ProtectedRouteType = withProtection(
    ({ children }) => <ModalLayout>{children}</ModalLayout>
);

export const SSORoute: ProtectedRouteType = withProtection(({ children }) => (
    <ModalLayout>{children}</ModalLayout>
));

export const LogoutRoute: ProtectedRouteType = withProtection(
    ({ children }) => <>{children}</>
);

export const LoginRoute: ProtectedRouteType = withProtection(({ children }) => (
    <ModalLayout>{children}</ModalLayout>
));
