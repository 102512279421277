import React, { FC } from 'react';
import { Icon, Text, useTooltip } from '@atlasgroup/react-atlantic';
import { Link } from '@atlasgroup/react-atlantic/lib/components/Typography/Link';
import { TooltipRoot } from '@components/TooltipRoot';
import { translate, translation } from '@translations/translation';
import { StyledLinkManual, StyledModalFooter } from './style';

export const ModalFooter: FC = ({ children }) => (
    <StyledModalFooter>
        <TooltipRoot
            data-title={translate(translation.common.support)}
            ref={useTooltip()}
            data-position="bottom"
        >
            <Link
                href="mailto:klientske.centrum@atlasgroup.cz"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Icon name="mailOpened" />
                <Text>klientske.centrum@atlasgroup.cz</Text>
            </Link>
        </TooltipRoot>
        <TooltipRoot
            data-title={translate(translation.common.manualHint)}
            ref={useTooltip()}
            data-position="bottom"
        >
            <StyledLinkManual
                href="http://manazerdatovychschranek.s3.amazonaws.com/migrace.pdf"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Icon name="question" />
                <Text>{translate(translation.common.manual)}</Text>
            </StyledLinkManual>
        </TooltipRoot>
        {children}
    </StyledModalFooter>
);
