import { styled } from '@atlasgroup/react-atlantic/lib';

export const StyledSummaryErrorContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StyledSummaryErrorProgressCircleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.margin.md};
`;
