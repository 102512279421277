import { StyledDeviceProps } from '@styles/types';
import { styled } from '@theme/styled';

interface StyledModalContentProps extends StyledDeviceProps {}

export const StyledModalContent = styled.div<StyledModalContentProps>`
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px ${({ theme }) => theme.padding.xl};
    background: ${({ theme }) => theme.color.background.alpha};
    text-align: center;
`;
