import { styled } from '@atlasgroup/react-atlantic/lib';
import { StyledButton } from '@atlasgroup/react-atlantic/lib/components/Button/view/default/styles';

export const StyledSummaryContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${StyledButton} {
        margin-top: ${({ theme }) => theme.margin.md};
    }
`;

export const StyledSummaryProgressCircleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.margin.md};
`;
