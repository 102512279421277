import { styled } from '@atlasgroup/react-atlantic/lib';
import { StyledText } from '@atlasgroup/react-atlantic';

export const StyledStepText = styled.div`
    display: flex;
    align-items: center;

    ${StyledText}:first-of-type {
        margin-right: ${({ theme }) => theme.margin.sm};
        color: ${({ theme }) => theme.color.text.gamma};
    }

    ${StyledText}:nth-of-type(2) {
        color: ${({ theme }) => theme.color.text.beta};
    }

    ${StyledText}:last-of-type {
        margin-left: ${({ theme }) => theme.margin.sm};
        color: ${({ theme }) => theme.color.text.beta};
    }
`;
