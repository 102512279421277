import { useNavigateWithURL } from '@hooks/useNavigateWithURL';
import { useCallback } from 'react';
import { path } from '@routes/path';

export type UseSummaryFooterValue = () => void;

export type UseSummaryFooterType = () => UseSummaryFooterValue;

export const useSummaryFooter: UseSummaryFooterType = () => {
    const navigate = useNavigateWithURL();

    return useCallback(
        () =>
            navigate({
                navigateParams: {
                    to: `/${path.recordsManagement}`,
                },
            }),
        [navigate]
    );
};
