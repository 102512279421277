import { StyledIcon, StyledTitle5 } from '@atlasgroup/react-atlantic/lib';
import { css, DeviceType } from '@atlasgroup/react-atlantic';
import { StyledButton } from '@atlasgroup/react-atlantic/lib/components/Button/view/default/styles';
import { StyledDeviceProps } from '@styles/types';
import { styled } from '@theme/styled';

export const StyledOverlay = styled.div`
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: ${(props) => props.theme.padding.xl};
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    cursor: zoom-out;
`;

export const StyledCenteredModal = styled.div<StyledDeviceProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: ${({ theme }) => theme.radius};
    cursor: default;

    ${({ currentDevice }) =>
        (['desktop', 'laptop', 'tabletHorizontal'] as DeviceType[]).includes(
            currentDevice
        )
            ? css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: 600px;
              `
            : css`
                  position: relative;
                  margin: auto;
              `}
`;

export const StyledCenteredModalHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({ theme }) => theme.color.background.epsilon};
    padding: ${({ theme }) => theme.padding.md}
        ${({ theme }) => theme.padding.xl};
    border-radius: ${({ theme }) => theme.radius} ${({ theme }) => theme.radius}
        0 0;

    ${StyledTitle5} {
        margin: 0;
        color: ${({ theme }) => theme.color.text.gamma};
    }

    ${StyledIcon} {
        color: ${({ theme }) => theme.custom?.color.text.zeta};
    }

    ${StyledButton} {
        &:hover {
            background: ${({ theme }) => theme.custom?.color.border.custom};

            ${StyledIcon} {
                color: ${({ theme }) => theme.color.text.gamma};
            }
        }
    }
`;

export const StyledCenteredModalBody = styled.div`
    overflow: auto;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    width: 100%;
    padding: 40px;
    background: ${({ theme }) => theme.color.background.alpha};
    text-align: center;
`;

export const StyledCenteredModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: ${(props) => props.theme.padding.md}
        ${(props) => props.theme.padding.xl};
    background: ${(props) => props.theme.color.background.alpha};
    border-top: 1px solid ${(props) => props.theme.color.border};
    text-align: right;

    ${StyledButton} + ${StyledButton} {
        margin-left: ${(props) => props.theme.margin.md};
    }
`;
