import { commonPath } from '../common';

export const ssoPath = {
    ...commonPath,
    ssoToken: ':ssoToken',
};

export type SSOPathTokenArg = {
    ssoToken?: string;
};
