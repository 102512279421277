import { StyledStrongText, StyledText } from '@atlasgroup/react-atlantic';
import { styled } from '@theme/styled';

export const StyledCard = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: ${({ theme }) => theme.radius};
    background: ${({ theme }) => theme.color.background.gamma};
    border-left: 4px solid ${({ theme }) => theme.color.border};
    margin-bottom: ${({ theme }) => theme.margin.sm};
    padding: ${({ theme }) => theme.padding.sm}
        ${({ theme }) => theme.padding.md};

    ${StyledText} {
        margin: 0;
    }

    ${StyledStrongText} {
        margin: 0;
    }
`;
