import { styled } from '@atlasgroup/react-atlantic/lib';
import { StyledCustomIcon } from '@components/Icon/Custom/style';

export const VerificationContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${StyledCustomIcon}:first-of-type {
        position: absolute;
        top: 0;
        width: 80px;
        height: 80px;

        circle {
            stroke: ${({ theme }) => theme.color.primary.alpha};
            stroke-width: 5px;
        }
    }

    ${StyledCustomIcon} {
        position: absolute;
        width: 16px;
        height: 16px;
    }
`;

export const StyledVerificationLoadingIconsContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
`;
