export class InvalidFieldException extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'InvalidFieldException';
    }
}

export const invalidFieldException = (key: any) =>
    new InvalidFieldException(
        `Cannot assign a listener to field '${key}' as it is not recognized as a state property key, this is a noop. If you wish to make your state extensible, disable the 'static' option.`
    );
