/**
 * Types LoginSsoStatus, ProductKey are taken from MDS generated graphql code,
 * so it would be useful to update them from time to time
 */
export enum LoginSsoStatus {
    MultipleLicenses = 'MULTIPLE_LICENSES',
    Ok = 'OK',
    TokenValidationError = 'TOKEN_VALIDATION_ERROR',
    UmConnectionError = 'UM_CONNECTION_ERROR',
    UsersCountExceeded = 'USERS_COUNT_EXCEEDED',
    UserHasNoLicenses = 'USER_HAS_NO_LICENSES',
}

enum ProductKey {
    ChytraDatovka = 'CHYTRA_DATOVKA',
    Datovka = 'DATOVKA',
    Mdso = 'MDSO',
}

export type SSOLicenses = Array<{
    licenseKey: string;
    licenseLabel: string;
    productKey: ProductKey;
}>;

export type SSOLoginResponse = {
    loginStatus: LoginSsoStatus;
    payload?: SSOLicenses;
    appToken?: string;
};
