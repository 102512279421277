import { FC } from 'react';
import { StyledLogo, StyledLogoContainer } from './style';

type LogoProps = Omit<JSX.IntrinsicElements['div'], 'ref'> & {
    onlyImage?: boolean;
};

export const Logo: FC<LogoProps> = ({ onlyImage, ...containerProps }) => {
    let Logo = (
        <svg viewBox="0 0 151 57" xmlns="http://www.w3.org/2000/svg">
            <g fillRule="nonzero" fill="none">
                <path
                    d="M141.694 15.628c1.33 0 4.902.266 6.916.95v3.914a24.46 24.46 0 00-1-.21v.21c-2.32-.538-4.787-.719-6.273-.753-3.443.089-5.115 1.187-5.115 3.261 0 3.04 2.014 3.154 5.624 3.306 4.94.19 8.968 1.634 8.968 7.942 0 5.662-4.598 8.36-11.362 8.36-.135 0-.293-.002-.47-.006l-.194.004-.336.002c-1.368 0-5.13-.19-8.056-1.14v-4.142c.32.086.655.164.999.237l.001-.237c2.439.653 5.716.902 7.446.943 4.24-.104 5.614-1.555 5.614-3.641 0-3.04-1.976-3.306-5.624-3.496-5.054-.266-8.968-1.558-8.968-7.6 0-5.624 4.37-7.904 10.83-7.904.116 0 .25.002.398.006l.275-.004zm-42.522.608V42h-6.13V31.703c.01-2.996.087-4.75.234-7.026a48.603 48.603 0 01-1.59 3.757l-4.218 8.74h-4.762l-4.218-8.74a48.676 48.676 0 01-1.59-3.757c.156 2.417.234 4.245.234 7.595V42h-6.054V16.236h7.878l6.168 13.259 6.17-13.259h7.878zM104.328 42V16.236h10.31c4.104 0 7.41.874 9.424 3.002 1.976 2.09 3.002 5.396 3.002 9.88 0 4.712-1.102 8.132-3.23 10.184C121.82 41.24 119.198 42 115.17 42h-10.842zm10.032-21.698h-3.788v17.632h3.75c1.596 0 2.964-.152 3.99-1.14 1.368-1.33 2.166-3.648 2.166-7.676 0-3.838-.722-6.156-2.052-7.562-1.026-1.102-2.432-1.254-4.066-1.254z"
                    fill="currentColor"
                />
                <path
                    d="M54.702 56.797H3.721a2.856 2.856 0 01-2.856-2.856V2.96A2.856 2.856 0 013.721.104h50.982a2.856 2.856 0 012.856 2.856v50.982a2.857 2.857 0 01-2.857 2.855z"
                    fill="#D20A11"
                />
                <path
                    d="M33.177 31.719a6.207 6.207 0 01-3.967 1.429 6.206 6.206 0 01-3.965-1.429L10.952 19.874v19.67c0 1.183.959 2.142 2.142 2.142h32.235a2.142 2.142 0 002.142-2.142V19.873L33.177 31.719z"
                    fill="#FFF"
                />
                <path
                    d="M27.493 29.004a2.694 2.694 0 003.436 0L46.41 16.175c-.322-.194-.678-.335-1.081-.335H13.094c-.403 0-.759.141-1.081.335l15.48 12.829z"
                    fill="#fff"
                />
            </g>
        </svg>
    );

    if (onlyImage) {
        Logo = (
            <svg viewBox="0 0 151 57" xmlns="http://www.w3.org/2000/svg">
                <g fillRule="nonzero" fill="none">
                    <path
                        d="M54.702 56.797H3.721a2.856 2.856 0 01-2.856-2.856V2.96A2.856 2.856 0 013.721.104h50.982a2.856 2.856 0 012.856 2.856v50.982a2.857 2.857 0 01-2.857 2.855z"
                        fill="#D20A11"
                    />
                    <path
                        d="M33.177 31.719a6.207 6.207 0 01-3.967 1.429 6.206 6.206 0 01-3.965-1.429L10.952 19.874v19.67c0 1.183.959 2.142 2.142 2.142h32.235a2.142 2.142 0 002.142-2.142V19.873L33.177 31.719z"
                        fill="#FFF"
                    />
                    <path
                        d="M27.493 29.004a2.694 2.694 0 003.436 0L46.41 16.175c-.322-.194-.678-.335-1.081-.335H13.094c-.403 0-.759.141-1.081.335l15.48 12.829z"
                        fill="#fff"
                    />
                </g>
            </svg>
        );
    }

    return (
        <StyledLogoContainer {...containerProps}>
            <StyledLogo>{Logo}</StyledLogo>
        </StyledLogoContainer>
    );
};
