import { styled } from '@theme/styled';
import { StyledDeviceProps } from '@styles/types';
import {
    StyledIcon,
    StyledIndicator,
    StyledText,
} from '@atlasgroup/react-atlantic';
import { StyledRouteLink } from '@components/Link/Route/style';

export const StyledHeader = styled.header<StyledDeviceProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: ${({ theme }) => theme.padding.md}
        ${({ theme }) => theme.padding.xl};
    background: ${({ theme }) => theme.color.background.epsilon};
    color: ${({ theme }) => theme.color.text.gamma};

    ${StyledText} {
        color: ${({ theme }) => theme.color.text.gamma};
    }
`;

export const StyledHeaderLeftContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledHeaderRightContainer = styled.div`
    display: flex;
    align-items: center;

    ${StyledIcon} {
        margin-right: ${({ theme }) => theme.margin.md};
    }

    ${StyledIndicator} {
        margin: 0 ${({ theme }) => theme.margin.xl};
    }

    ${StyledRouteLink} {
        color: ${({ theme }) => theme.color.text.gamma};
        text-decoration: underline;
    }
`;
