import React, { FC } from 'react';
import { ProgressCircle, Text } from '@atlasgroup/react-atlantic';
import { translate, translation } from '@translations/translation';
import { useProgressContent } from '@pages/Progress/components/Content/hooks/useProgressContent';
import { getPercentage } from '@pages/Progress/components/Content/hooks/utils/getPercentage';
import { StyledProgressContentCircleContainer } from './style';

export type ProgressContentProps = {};
export type ProgressContentType = FC<ProgressContentProps>;

export const ProgressContent: ProgressContentType = () => {
    const importingProgress = useProgressContent();

    return (
        <>
            <StyledProgressContentCircleContainer>
                <ProgressCircle
                    value={getPercentage(importingProgress!) || 0}
                    circleSize={80}
                />
            </StyledProgressContentCircleContainer>
            <Text element={'strong'}>
                {translate(translation.progress.loading)}
            </Text>
            <Text>{translate(translation.progress.mayTakeAWhile)}</Text>
        </>
    );
};
