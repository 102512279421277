import React, { FC } from 'react';
import { Button, ProgressCircle, Text } from '@atlasgroup/react-atlantic';
import { translate, translation } from '@translations/translation';
import {
    StyledSummaryErrorContentContainer,
    StyledSummaryErrorProgressCircleContainer,
} from './style';
import { useModal } from '@hooks/useModal/useModal';
import { Modals } from '@components/CenteredModal/constants/Modals';

export type SummaryErrorContentProps = {};
export type SummaryErrorContentType = FC<SummaryErrorContentProps>;

export const SummaryErrorContent: SummaryErrorContentType = (_) => {
    const { toggleOpen } = useModal(Modals.SummaryDetail);

    return (
        <StyledSummaryErrorContentContainer>
            <StyledSummaryErrorProgressCircleContainer>
                <ProgressCircle value={100} circleSize={80} failed />
            </StyledSummaryErrorProgressCircleContainer>
            <Text element={'strong'}>
                {translate(translation.summary.error.failure)}
            </Text>
            <Text>{translate(translation.summary.error.thereWasAnError)}</Text>
            <Button onClick={toggleOpen}>
                {translate(translation.summary.error.details)}
            </Button>
        </StyledSummaryErrorContentContainer>
    );
};
