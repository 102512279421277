import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@layouts/ModalLayout/components';
import { UsersContent, UsersFooter } from './components';

export type UsersProps = {};
export type UsersType = FC<UsersProps & RouteComponentProps>;

export const Users: UsersType = () => {
    return (
        <>
            <ModalHeader currentStepIndex={2} />
            <ModalContent>
                <UsersContent />
            </ModalContent>
            <ModalFooter>
                <UsersFooter />
            </ModalFooter>
        </>
    );
};
