import { useQuery } from 'react-query';
import { API, ImportType, UserDto } from '@api';
import { useEditedUsersStore } from '@pages/Users/store/useEditedUsersStore';
import { useMemo } from 'react';
import { SYSDBA_ID } from '../constants/SYSDBA';

const fukinFnType = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery<Array<UserDto>>(undefined as any);

export type UseUsersValue = {
    users: Array<UserDto>;
    numberOfImportedUsers: number;
} & ReturnType<typeof fukinFnType>;

export type UseUsersType = (maxSize?: number | null) => UseUsersValue;

export const useUsers: UseUsersType = (maxSize) => {
    const [editedUsers, setEditedUsers] = useEditedUsersStore('users');

    const numberOfImportedUsers = useMemo(
        () =>
            editedUsers.reduce((acc, current) => {
                if (current.importType === ImportType.Include) {
                    return acc + 1;
                }
                return acc;
            }, 0),
        [editedUsers]
    );

    return {
        ...useQuery<Array<UserDto>>('users', () => API.get('/users'), {
            onSuccess: (users) => {
                const size = editedUsers?.length ?? 0;
                const isEmpty = !!size;
                if (!isEmpty) {
                    const usersWithImport = users.filter(
                        (u) => u.importType === ImportType.Include
                    );

                    const usersWithImportSize = usersWithImport.length;
                    let tranformedUsers = users;

                    if (maxSize && usersWithImportSize > maxSize) {
                        let counter = maxSize - usersWithImportSize;
                        for (
                            let index = tranformedUsers.length - 1;
                            index >= 0;
                            index--
                        ) {
                            if (!counter) break;
                            if (tranformedUsers[index].id !== SYSDBA_ID) {
                                tranformedUsers[index].importType =
                                    ImportType.Exclude;
                                counter++;
                            }
                        }
                    }
                    setEditedUsers(tranformedUsers);
                }
            },
        }),
        users: editedUsers,
        numberOfImportedUsers: numberOfImportedUsers,
    };
};
