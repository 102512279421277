import React, { FC } from 'react';
import {
    RecordsManagementContentContainer,
    RecordsManagementTextContainer,
} from './styles';
import { Text } from '@atlasgroup/react-atlantic';
import { translate, translation } from '@translations/translation';
import { CustomIcon } from '@components/Icon';
import { Link } from '@atlasgroup/react-atlantic/lib/components/Typography/Link';

export const RecordsManagementContent: FC = () => (
    <RecordsManagementContentContainer>
        <CustomIcon name="warning" type="error" />
        <Text element={'strong'}>
            {translate(translation.recordsManagement.heading)}
        </Text>
        <RecordsManagementTextContainer>
            <Text>
                {translate(translation.recordsManagement.hssInfoPrefix)}
            </Text>
            <Text element="strong">
                {translate(translation.recordsManagement.hssInfoStrong)}
            </Text>
            <Text>
                {translate(translation.recordsManagement.hssInfoSuffix)}
            </Text>
        </RecordsManagementTextContainer>
        <RecordsManagementTextContainer>
            <Text>
                {translate(translation.recordsManagement.helpNeededPrefix)}
            </Text>
            <Link
                href={`http://${translate(
                    translation.recordsManagement.helpNeededLink
                )}`}
                target="_blank"
            >
                {translate(translation.recordsManagement.helpNeededLink)}
            </Link>
            <Text>
                {translate(translation.recordsManagement.helpNeededSuffix)}
            </Text>
        </RecordsManagementTextContainer>
    </RecordsManagementContentContainer>
);
