import { createGlobalStyle } from '@atlasgroup/react-atlantic';

export const GlobalStyle = (createGlobalStyle as any)`
    * {
        box-sizing: border-box;
    }

    html,
    body,
    #root {
        height: 100%;
    }

    body {
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    


    @media only screen and (min-width: 910px){
        /*Firefox Scrollbars*/
        * {
            scrollbar-width: thin;         
            scrollbar-color: ${(props: any) =>
                props.theme.color.text.beta + '80'} 
            ${(props: any) => props.theme.color.border};    
        } 

        *:hover {
            scrollbar-color: ${(props: any) => props.theme.color.text.beta} 
            ${(props: any) => props.theme.color.border};  
        }

        /*Other Scrollbars*/
        *::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }

        *::-webkit-scrollbar-track {
            background-color: ${(props: any) => props.theme.color.border}; 
        }

        *::-webkit-scrollbar-corner {
            background-color: ${(props: any) => props.theme.color.border}; 
        }
        
        *::-webkit-scrollbar-thumb {
            background-color: ${(props: any) =>
                props.theme.color.text.beta + '80'};
            border: 2px solid ${(props: any) => props.theme.color.border};  
        }  

        *:hover::-webkit-scrollbar-thumb {
            background-color: ${(props: any) => props.theme.color.text.beta};
        } 
    }
`;
