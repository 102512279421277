import { FC } from 'react';
import { createAtlanticProvider } from '@atlasgroup/react-atlantic';
import { QueryClientProvider } from 'react-query';
import { Tooltips } from '@components/Tooltips/Tooltips';
import { GlobalStyle } from '@styles/Global.style';
import { Router } from '@routes/Router';
import { light } from '@theme/theme';
import { queryClient } from './api/client';
import { createTranslations } from '@translations/i18n';

export interface AppProps {}
export type AppType = FC<AppProps>;

const AtlanticProvider = createAtlanticProvider<typeof light>();
createTranslations();

export const App: AppType = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <AtlanticProvider theme={light}>
                <Tooltips>
                    <GlobalStyle />
                    <Router />
                </Tooltips>
            </AtlanticProvider>
        </QueryClientProvider>
    );
};
