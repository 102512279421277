export const commonPath = { root: '/' };

export const path = {
    ...commonPath,
    users: 'users',
    sso: 'sso',
    logout: 'logout',
    login: 'login',
    init: 'init',
    verify: 'verify',
    progress: 'progress',
    summary: 'summary',
    recordsManagement: 'records-management',
};

export type PathKeys = keyof typeof path;
