import { ButtonProps, SimpleButton } from '@atlasgroup/react-atlantic';
import React, { FC } from 'react';
import { CustomIcon } from '../../../Icon';
import { useModal } from '@hooks/useModal/useModal';
import { Modals } from '@components/CenteredModal/constants/Modals';

export interface ModalCloseButtonProps {
    modalKey: Modals;
    render?: FC<ButtonProps>;
}
export type ModalCloseButtonType = FC<ModalCloseButtonProps>;

export const ModalCloseButton: ModalCloseButtonType = ({
    render = SimpleButton,
    modalKey,
}) => {
    const { toggleOpen } = useModal(modalKey);
    const RenderComponent = render;

    return (
        <RenderComponent onClick={toggleOpen}>
            <CustomIcon name="close" />
        </RenderComponent>
    );
};
