import { useCallback, useMemo } from 'react';
import { path } from '@routes/path';

const FILTER: string[] = [];
export type UseUrlValue = {
    setPathName: (pathName: string) => void;
    setParams: (paramEntries: Map<string, string>) => void;
    removeParams: (params: Set<string>) => void;
    removeAllParamsBut: (params: Set<string>) => void;
    removeAllParams: () => void;
    getParam: (paramName: string) => string | null;
    url: URL;
};

export type UseUrlProps = URL;
export type UseURLType = (defaultURL?: UseUrlProps) => UseUrlValue;

export const useURL: UseURLType = (defaultURL) => {
    const windowHref = window.location.href;
    const url = useMemo(() => defaultURL ?? new URL(windowHref), [
        defaultURL,
        windowHref,
    ]);

    const searchParams = url.searchParams;

    const setParams: UseUrlValue['setParams'] = useCallback(
        (paramEntries = new Map()) =>
            paramEntries.forEach((value, key) =>
                searchParams.has(key)
                    ? searchParams.set(key, value)
                    : searchParams.append(key, value)
            ),
        [searchParams]
    );

    const removeParams: UseUrlValue['removeParams'] = useCallback(
        (params = new Set(FILTER)) =>
            params.forEach(
                (key) => searchParams.has(key) && searchParams.delete(key)
            ),
        [searchParams]
    );

    const removeAllParams: UseUrlValue['removeAllParams'] = useCallback(
        () => removeParams(new Set(searchParams.keys())),
        [removeParams, searchParams]
    );

    const removeAllParamsBut: UseUrlValue['removeAllParamsBut'] = useCallback(
        (paramsToKeep = new Set()) => {
            const paramsToRemove = [...searchParams.keys()].filter(
                (item) => !paramsToKeep.has(item)
            );
            removeParams(new Set(paramsToRemove));
        },
        [removeParams, searchParams]
    );

    const setPathName: UseUrlValue['setPathName'] = useCallback(
        (pathName = path.root) => (url.pathname = pathName),
        [url]
    );

    const getParam: UseUrlValue['getParam'] = useCallback(
        (paramName) => searchParams.get(paramName),
        [searchParams]
    );

    return {
        setParams,
        removeAllParamsBut,
        removeParams,
        setPathName,
        url,
        removeAllParams,
        getParam,
    };
};
