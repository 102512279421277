import { Store } from '../../Store';
import { invalidFieldException } from './errors/invalidFieldException';
import { mapObservers } from './utils/mapObservers';

type ReactiveOptionsType = {
    static: boolean;
};
const DEFAULT_OPTIONS: ReactiveOptionsType = {
    static: false,
};

type ObserverMethodType<T extends {}> = <K extends keyof T>(
    key: K,
    callback: (updatedValue: T[K]) => void
) => void;

type ObserversType<T extends {}> = Record<keyof T, Function[]>;

export type Reactive<T extends {}> = {
    observers: ObserversType<T>;
    registerObserver: ObserverMethodType<T>;
    unregisterObserver: ObserverMethodType<T>;
};

export const reactive = (
    reactiveOptions: Partial<ReactiveOptionsType> = {}
) => <T extends Store<any>>(store: T): T & Reactive<T['state']> => {
    const options = { ...DEFAULT_OPTIONS, ...reactiveOptions };

    const reactiveStore = Object.assign(store, {
        observers: mapObservers(store.state),

        registerObserver(key, callback) {
            if (!options.static && !this.observers[key]) {
                // Register a field first
                this.observers[key] = [];
            }

            if (this.observers[key]) this.observers[key].push(callback);
            else throw invalidFieldException(key);
        },

        unregisterObserver(key, callback) {
            if (this.observers[key])
                this.observers[key] = this.observers[key].filter(
                    (currentCallback) => currentCallback !== callback
                );
            else throw invalidFieldException(key);
        },
    } as Reactive<T['state']> & T);

    reactiveStore.pipelines.set.push((result, { parameters: [key], store }) => {
        const currentStore = store as Store<any> & Reactive<any>;

        if (!options.static && !currentStore.observers[key]) {
            // Register a field first
            currentStore.observers[key] = [];
        }

        if (currentStore.observers[key])
            currentStore.observers[key].forEach((cb) => cb(result));
        else throw invalidFieldException(key);

        return result;
    });

    return reactiveStore;
};
