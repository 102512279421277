import { reactive, state, Store } from '@utils/Store';
import { Modals } from '@components/CenteredModal/constants/Modals';

export type ModalStoreType = {
    visibilityMap: Map<Modals, boolean>;
};

const template = new Store<ModalStoreType>({
    visibilityMap: new Map(),
});

const modalStore = reactive()(template);

export const useModalStoreState = state(modalStore);
