import { useEffect } from 'react';
import { SSO_URL } from '@env/config';
import { path } from '@routes/path';
import { useNavigateWithURL } from '@hooks/useNavigateWithURL';
import { removeAccessToken } from '@utils/token';

export const useSSOLogout = () => {
    const navigate = useNavigateWithURL();
    return useEffect(() => {
        removeAccessToken();
        navigate(
            //! Must include a trailing slash
            {
                navigateParams: {
                    to: `${SSO_URL}/?return=${window.location.origin}/${path.sso}/&logout`,
                    options: { replace: true },
                },
            }
        );
    }, [navigate]);
};
