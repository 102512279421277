import {
    InitializationStoreState,
    useInitializationStore,
} from '@pages/Initialization/store/useInitializationStore';
import { useCallback } from 'react';
import { InputFixProps } from '@atlasgroup/react-atlantic/lib/components/Input/view/composed/withFix/types';
import { InitInput } from '@api';
import { useInitializationFooter } from '@pages/Initialization/components/Footer/hooks/useInitializationFooter';

export type UseInitializationContentValue = {
    onFieldChange: (
        field: keyof InitInput
    ) => NonNullable<InputFixProps['onChange']>;
    onEnterPress: NonNullable<InputFixProps['onKeyDown']>;
} & InitializationStoreState;

export type UseInitializationContentType = () => UseInitializationContentValue;

export const useInitializationContent: UseInitializationContentType = () => {
    const { onContinueClick } = useInitializationFooter();
    const [input, setInput] = useInitializationStore('input');

    const onFieldChange = useCallback<
        UseInitializationContentValue['onFieldChange']
    >(
        (field) => (event) => {
            setInput((prev) => {
                const newInput = { ...prev };
                newInput[field] = event.currentTarget.value;
                return newInput;
            });
        },
        [setInput]
    );

    const onEnterPress = useCallback<
        UseInitializationContentValue['onEnterPress']
    >((event) => event.key === `Enter` && onContinueClick(), [onContinueClick]);

    return { input, onFieldChange, onEnterPress };
};
