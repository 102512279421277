import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ErrorMessage } from '@components/ErrorMessage';
import { useErrorMessage } from '@hooks/useErrorMessage/useErrorMessage';
import {
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@layouts/ModalLayout/components';
import {
    RecordsManagementContent,
    RecordsManagementFooter,
} from './components';
import { translate, translation } from '@translations/translation';

export type RecordsManagementType = FC<RouteComponentProps>;

export const RecordsManagement: RecordsManagementType = () => {
    const errorMessage = useErrorMessage('init').errorMessage;

    return (
        <>
            <ModalHeader
                currentStepIndex={4}
                title={translate(translation.recordsManagement.title)}
            />
            <ModalContent>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <RecordsManagementContent />
            </ModalContent>
            <ModalFooter>
                <RecordsManagementFooter />
            </ModalFooter>
        </>
    );
};
