import { styled, StyledStrongText } from '@atlasgroup/react-atlantic/lib';
import { StyledIcon } from '@atlasgroup/react-atlantic';
import { StyledLink } from '@atlasgroup/react-atlantic/lib/components/Typography/Link';

export const RecordsManagementContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    max-width: 730px;

    ${StyledStrongText} {
        margin: 0;
    }

    ${StyledIcon} {
        width: 60px;
        height: 60px;
    }
`;

export const RecordsManagementTextContainer = styled.div`
    display: inline-block;

    ${StyledStrongText} {
        margin: 0 ${({ theme }) => theme.margin.sm};
    }

    ${StyledLink} {
        color: ${({ theme }) => theme.color.primary.alpha};
        margin: 0 ${({ theme }) => theme.margin.sm};
    }
`;
