import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { registerLocalDatePicker } from './DatePickerRegister';
import { settings } from './settings';

const languageDetector = new LanguageDetector();
export const createTranslations = () =>
    i18next.use(languageDetector).use(initReactI18next).init(settings);

registerLocalDatePicker();
