import React, { FC } from 'react';
import { CenteredModal } from '@components/CenteredModal/CenteredModal';
import { Modals } from '@components/CenteredModal/constants/Modals';
import { translate, translation } from '@translations/translation';
import { SummaryDetailContent, SummaryDetailFooter } from './components';

export type SummaryDetailModalType = FC;

export const SummaryDetailModal: SummaryDetailModalType = () => (
    <CenteredModal
        header={translate(translation.summary.modal.title)}
        content={<SummaryDetailContent />}
        footer={<SummaryDetailFooter />}
        modalKey={Modals.SummaryDetail}
    />
);
