import React, { FC } from 'react';
import { translate, translation } from '@translations/translation';
import { useRecordsManagementFooter } from '@pages/RecordsManagement/components/Footer/hooks/useRecordsManagementFooter';
import { Button } from '@atlasgroup/react-atlantic/lib';

export const RecordsManagementFooter: FC = () => {
    const { onContinueClick } = useRecordsManagementFooter();

    return (
        <Button onClick={onContinueClick} type="primary">
            {translate(translation.recordsManagement.continueToApplication)}
        </Button>
    );
};
