import { StyledIcon, StyledText } from '@atlasgroup/react-atlantic';
import { StyledButton } from '@atlasgroup/react-atlantic/lib/components/Button/view/default/styles';
import {
    Link,
    StyledLink,
} from '@atlasgroup/react-atlantic/lib/components/Typography/Link';
import { styled } from '@theme/styled';

export const StyledModalFooter = styled.div`
    display: flex;
    align-content: center;
    gap: 20px;
    width: 100%;
    padding: ${({ theme }) => `${theme.padding.md} ${theme.padding.xl} `};
    background: ${({ theme }) => theme.color.background.alpha};
    border-top: 1px solid ${({ theme }) => theme.color.border};

    ${StyledLink} {
        display: flex;
        align-items: center;
        gap: 5px;
        margin: 0;
        text-decoration: none;
    }

    ${StyledButton} {
        margin-left: auto;
    }
`;

export const StyledLinkManual = styled(Link)`
    ${StyledText}, ${StyledIcon} {
        color: ${({ theme }) => theme.color.text.beta};
    }
`;
