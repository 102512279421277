import React, { FC } from 'react';
import { Icon, Input, useDevice, useTooltip } from '@atlasgroup/react-atlantic';
import { UserDto } from '@api';
import {
    StyledUserChoiceContainer,
    StyledUserContainer,
    StyledUserInformationContainer,
} from './styles';
import { useUser } from '@pages/Users/components/Content/components/User/hooks/useUser';
import { Switch } from '@atlasgroup/react-atlantic/lib/components/Switch';
import { SYSDBA_ID } from '@pages/Users/constants/SYSDBA';
import { ValidationErrorMessage } from '@pages/Users/types/ValidationErrorMessage';

export type UserProps = {
    user: UserDto;
    index: number;
    isDuplicated?: boolean;
    isSwitchDisabled?: boolean;
};
export type UserType = FC<UserProps>;

export const User: UserType = ({
    user,
    index,
    isDuplicated = false,
    isSwitchDisabled,
}) => {
    const { currentDevice } = useDevice();
    const {
        isIncluded,
        onIncludedChange,
        onUserInformationChange,
        isValid,
        userValidityMessage,
    } = useUser(user, index, isDuplicated, isSwitchDisabled);
    const tooltip = useTooltip();

    const isDisabled = isSwitchDisabled || SYSDBA_ID == user.id || !isIncluded;
    const mergedValid = (isValid && !isDuplicated) || !isIncluded;

    return (
        <StyledUserContainer isValid={mergedValid}>
            <StyledUserInformationContainer currentDevice={currentDevice}>
                <Input defaultValue={user?.id!} isDisabled isFullWidth />
                <Input
                    isDisabled={isSwitchDisabled || !isIncluded}
                    defaultValue={user?.name!}
                    onChange={onUserInformationChange('name')}
                    isFullWidth
                />
                <Input
                    isDisabled={isDisabled}
                    defaultValue={user?.email!}
                    onChange={onUserInformationChange('email')}
                    isFullWidth
                />
            </StyledUserInformationContainer>
            <StyledUserChoiceContainer
                ref={tooltip}
                data-position={'right'}
                data-title={
                    userValidityMessage === ValidationErrorMessage.Valid ||
                    isDisabled
                        ? undefined
                        : userValidityMessage
                }
            >
                <Switch
                    type="success"
                    isDisabled={user.id === SYSDBA_ID || isSwitchDisabled}
                    onClick={onIncludedChange}
                    isChecked={isIncluded}
                    textOn={<Icon name="check" />}
                    textOff={<Icon name="close" />}
                />
            </StyledUserChoiceContainer>
        </StyledUserContainer>
    );
};
