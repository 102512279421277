import { styled } from '@theme/styled';

export const StyledLogoContainer = styled.div`
    cursor: pointer;
    line-height: 0;
    padding-right: ${({ theme }) => theme.padding.xl};
    margin-right: ${({ theme }) => theme.margin.xl};
    border-right: 1px solid ${(props) => props.theme.color.text.beta};
`;

export const StyledLogo = styled.i`
    height: 40px;
    display: inline-block;
    color: ${(props) => props.theme.color.text.gamma};
    align-self: center;

    svg {
        height: 100%;
        display: inline-block;
    }
`;
